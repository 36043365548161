import { Weblayout } from "../../components/common/weblayout"
import { Reachout } from "../../containers/contact/sendmessage"
import { HowWeWork } from "../../containers/homepage/howwework"
import { AllServices, Servicehero} from "../../containers/services/servicescont"
export const Services = ()=> {

    return (
        <Weblayout>
            <Servicehero/>
            <AllServices/>
            <HowWeWork/>
            <Reachout/>
        </Weblayout>
    )
}