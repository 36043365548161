import cto from "../../assets/images/cto.png"

export const  Ctohero = ()=> {

    return (
        <section className="bg-velion py-[250px] h-full relative overflow-x-hidden overflow-y-hidden">
            <div className="container mx-auto flex items-end justify-between h-full">
                <span className="text-white text-left z-10 xl:max-w-[650px] flex flex-col gap-[20px]
                    xl:mt-[100px]
                ">
                    <p className="text-[4em] leading-[1] font-[700]"> Chief <br/> Technology Officer.</p>
                    <p className="text-[1.25em] leading-[2] font-[300]">
                        We offer consulting services for enterprises and investment firms based on our successful delivery of hundreds of digital projects and solutions for the world’s most demanding clients.
                    </p>
                </span>
                <img src={cto} alt="robot" className="xl:w-[700px] absolute bottom-[-10px] right-[-40px]" />
            </div>
        </section>
    )
}