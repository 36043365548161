import React from "react";
import { Hero } from "../containers/homepage/hero";
import { Industries } from "../containers/homepage/industries";
import { Whatwedo } from "../containers/homepage/whatwedo";
// import { Testimonial } from "../containers/homepage/testimonial";
import { Weblayout } from "../components/common/weblayout";
import { HowWeWork } from "../containers/homepage/howwework";
import { Reachout } from "../containers/contact/sendmessage";
export const Homepage = (props:any) => {

    return (
        <Weblayout>
            <Hero/>
            <Whatwedo/>
            <Industries/>
            {/* <Testimonial/> */}
            <HowWeWork/>
            <Reachout/>
        </Weblayout>
    )
}