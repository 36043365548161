import { Aboutus } from '../pages/about';
import { Contactus } from '../assets/svgs/contactus';
import { Dataserviceshome } from '../pages/services/dataservices';
import { Ctoserviceshome } from '../pages/services/ctoservices';
import { Comingsoon } from '../pages/comingsoon';
import { Devopshome } from '../pages/services/devops';
import { Startuphome } from '../pages/services/startup';
import { Softdevhome } from '../pages/services/softdev';
import { Softconsulthome } from '../pages/services/softconsult';
import { Traininghome } from '../pages/services/training';
import { Services } from '../pages/services/services';
import { BlogTemplate } from '../pages/blog/[id]';

export const allRoute = [

    { path: '/aboutus', component: Aboutus},
    { path: '/contactus', component: Contactus},
    { path: '/services', component: Services},
    { path: '/services/ai-data-services', component: Dataserviceshome},
    { path: '/services/cto', component: Ctoserviceshome},
    { path: '/services/dedicated-teams', component: Devopshome},
    { path: '/services/startup-solutions', component: Startuphome},
    { path: '/services/softwaredev', component: Softdevhome},
    { path: '/services/software-services', component: Softconsulthome},
    { path: '/services/technical-trainings', component: Traininghome},
    { path: '/blog', component: Comingsoon},
    { path: '/blog/:id', component: BlogTemplate}
]