import React, {useRef, useState} from "react"
import emailjs from '@emailjs/browser';
import { Bigtitle } from "../../components/common/bigtitle";

import { GoMultiSelect } from 'react-icons/go';
// Animate presence

import { motion, AnimatePresence } from "framer-motion"

import { AiOutlineClose } from "react-icons/ai"
// import { Checkbox } from "../../components/common/checkboox"
import { Input, Textarea,} from "../../components/common/inputs"


export const Reachout =()=> {
    const [statusMessage, setStatusMessage] = useState("");

    const form = useRef<HTMLFormElement>(null);

    const sendEmail = (e: { preventDefault: () => void; }) => {
      e.preventDefault();
  
      emailjs.sendForm('service_czf00ho', 'template_yfwic6a', 
      form.current as HTMLFormElement,
       'x9qGpIo5XLoRbgMjg')
       
        .then((result: { text: string; }) => {
            
            console.log(result.text);
            setStatusMessage("Email sent successfully")

            
        }, (error: { text: string; }) => {
            console.log(error.text);
            setStatusMessage(`${error.text} happened`);

        });

        form.current?.reset();
    };
  
    // const [check, setCheck] = React.useState(false)

    return (
        <section className="w-full mx-auto flex items-center justify-center bg-grey-800 px-[50px] lg:px-[120px] py-[100px] ">
            <div className="container px-0 w-full mx-auto flex flex-col items-center gap-20 ">
                <Bigtitle
                    title="What Can We Build for You?"
                    subtitle="Let’s discuss your ideas. We will send you an NDA before we talk. All the information is kept confidential."
                    classes="!text-white xl:max-w-[750px] text-deepbrown"
                />

<div className="w-full relative flex flex-col items-center justify-center">
                <form className="flex flex-col gap-[45px] w-full"
                ref={form} onSubmit={sendEmail}>
                    <div className="flex flex-col gap-[30px] w-full">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
                            <Input placeholder="Your Name" type="text" icon="far fa-user" name="user_name" />
                            <Input placeholder="Phone" type="telephone" icon="fas fa-phone"  name="user_number"  /> 
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
                            <Input placeholder="Company Name" type="text"  icon="far fa-building"  name="user_company" />
                            <div
                            className=" w-full relative mt-1.5 rounded-lg border-gray-300 "
                            >

  <select
    name="user_select"
    id="SelectService"
    placeholder="Select your industry"
    className={`bg-grey-400 py-[16px] px-[20px] text-grey-500 outline-none
    border border-midgrey w-full rounded-[5px] pl-[55px] `}
  >
    <option  value="">Select A Service</option>
    <option value="AI and DataService">AI & Data Service</option>
    <option value="Software Services">Software Services</option>
    {/* <option value="Software Con">Software Consulting</option> */}
    <option value="Dedicated Teams">Dedicated Teams</option>
    <option value="StartUp and MVP">StartUp and MVP Solution</option>
    <option value="Technical Train">Technical Training</option>
    <option value="Others">Others</option>
  </select>

    <div className="pointer-events-none absolute inset-y-0 top-0 bottom-0 h-fit my-auto left-[15px] text-[1.5em] text-velion-600">
        <GoMultiSelect className="text-velion-600 text-3xl" />
        </div>


</div>
                        </div>
                        {/* select div */}
                        <div className=" w-full flex  gap-2">
                        

                        <Input placeholder="Email Address" type="email"  icon="far fa-envelope"  name="user_email" />
                        
</div>
                        <Textarea placeholder="How can we help?" type='text' name='message' />
                        {/* <Checkbox check={check} click={setCheck}>

                            <p className="font-[500] text-left text-[1em] text-white ">Send me the signed Non-Disclosure Agreement (NDA )</p>
                        </Checkbox> */}
                        <div className="flex items-center gap-2">
                            {/* when checked backgroundcolour should change to blue */}
                            {/* <input type="checkbox" name="user_check" id="user_check" className="w-6 h-6   "/> */}
                            <input type="checkbox" name="user_check" id="user_check"
                             value='user_check' className=" w-[1.3em] h-[1.3em] bg-transparent border-[2px] border-velion-300  appearance-none before:w-[0.65em] before:h-[0.65em] before:content-[''] checked:bg-velion-800 "/>
                            <label htmlFor="user_check" className="font-[500] text-left text-[1em] text-white ">Send me the signed Non-Disclosure Agreement (NDA )</label>
                        </div>

                    </div>
                    <div className="w-full rounded-[5px]">
                        {/* <Button name="Start a conversation"  addOns={`!rounded-b-[0px]`} /> */}
                        <button type='submit' 
                    // onClick={clearForm}
                        className='bg-velion-900 w-full h-[50px] text-white text-[1em] font-[600] flex items-center justify-center
            ouline-none border-none rounded-[5px] px-[30px] py-[16px] capitalize'
                        >Start a conversation</button>

                    </div>
                    
                </form>
                {/* created a popup message for this with a close button */}
                <motion.div 
                initial={{opacity: 0, y: -100}}
                animate={{opacity: 1, y: 0}}
                exit={{opacity: 0, y: -100}}
                transition={{duration: .5}}

                className="top-0 right-0 w-full h-full absolute z-[100] flex items-center justify-center "
                style={{display: statusMessage ? "flex" : "none"}}
                >
                    <AnimatePresence>
                    <div className="w-96 h-32 bg-[#3D5AA9] rounded-lg flex items-center relative justify-center">

                    <p className="text-white text-[1em] font-[500] ">{statusMessage}</p>

<div className="w-8 h-8 rounded-full flex items-center absolute top-0 right-0 justify-center">
                    <AiOutlineClose className="text-white text-xl font-[500] mr-[8px] cursor-pointer"
                     onClick={()=> setStatusMessage("")}/>

                    </div>
                    </div>
                    </AnimatePresence>
                    </motion.div>          

</div>               

            </div>
        </section>
    )
}