import React from "react";
import { Navbar } from "./navbar";
import { Footer } from "./footer";
import { useRef} from "react";
import { Pageloader } from "../../containers/loader";
export const Weblayout = ({children}:any)=> {
    const distance = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = React.useState(true)
    React.useEffect(()=> {
        var tt = setTimeout(()=> {
        setLoading(false)
        },2000)

        return ()=> {
        clearTimeout(tt)
        }
    
    },[])
    if (loading){
        return <Pageloader/>
    } else {
        return (
            <section className="h-fit flex flex-col">
    
                <Navbar
                    ref={distance}
                    dist={null !== distance.current &&   distance.current.offsetTop}
                    // dist ={ null !== distance.current &&   distance.current.offsetTop >= 200 ? 'bg-red-900':'xl:bg-transparent' }
                />
                <div className="-my-36 h-fit flex flex-col">
                    {
                        children
                    }
                    {null !== distance.current &&   distance.current.offsetTop}
                    <Footer/>
                </div>
                
            </section>
        )
    }
    
}