import { contacts } from "../../data/data"
import { ContactsModel } from "../../data/model"
import { handleContact } from "../../data/utils"

export const Contacthero = ()=> {
    return (
        // <section className="relative bg-velion-700 pt-[250px] xl:pt-[250px]  h-[80vh] md:h-[70em] xl:h-[80vh] mb-[75em] md:mb-[250px] xl:mb-[300px]">
        <>
       
        <section className="relative bg-white py-[200px]  md:pt-[250px]  md:h-[60em] xl:h-[50em] md:mb-[0px] xl:mb-[0px]">
            <div className="container h-full mx-auto flex flex-col items-center text-center">
               <span className="flex flex-col text-black items-center gap-[15px]">
                    <p className="text-[2em] md:text-[3.em] lg:text-[4.2em] font-[600]">
                        Let’s Connect
                    </p>
                    <p className=" text-sm md:text-base xl:text-lg 2xl:text-xl font-medium text-[#858a8f] max-w-[420px] xl:max-w-3xl 2xl:max-w-5xl">

                    Whether you’re searching for a new engineering partner or starting a new career, we would love to hear from you.


Contact us if you have any questions about our company or products.

Our team will provide an answer within a few days.
                    
                    </p>
               </span>
               
                {/* absolute xl:bottom-[-250px] */}
                {/* <div className="md:absolute md:w-[90vw] md:bottom-[-250px] lg:grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[20px]">
                    {
                        contacts.map((item,index)=> (
                            <Singlecontact
                                key={index}
                                data={item}
                            />
                        ))
                    }
                </div> */}
            </div>

        </section>
        <div className="mx-auto w-[90vw]  2xl:w-[65vw] h-[80em] md:h-[600px] xl:h-[25em] z-30 mt-[-10em] md:mt-[-20em] xl:mt-[-15em] mb-[10em] md:mb-[15em] xl:mb-[10em] lg:grid grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[20px]">
            {
                contacts.map((item,index)=> (
                    <Singlecontact
                        key={index}
                        data={item}
                    />
                ))
            }
        </div>
        </>
    )
}
interface contactProps {
    data:ContactsModel
}
const Singlecontact: React.FC<contactProps> = ({data})=> {

    return (
        <div className="  rounded-lg col-span-1 xl:last:col-span-1 md:last:col-span-2 text-left flex flex-col gap-10 shadow-lg shadow-grey-500 bg-lightgrey text-deepbrown p-10">
            <span className="flex flex-col gap-4">
                <div className=" w-24  h-24 rounded-full overflow-hidden">

                <img src={data.image} className="w-full mb-[10px]"  alt={data.title}/>
                </div>
              
                <p className="font-[700] text-[1.2em] md:text-[1.3em] xl:text-[1.8em]">
                    {data.title}
                </p>
                <p className="text-sm md:text-base font-medium text-[#858a8f] xl:max-w-[330px] h-[80px]">{data.subtitle}</p>
            </span>
            <span className="flex items-center gap-2 cursor-pointer text-black "  
            onClick={()=>handleContact(data.link, data.type)}>
                <div className="w-fit h-fit">

                <i className=" fas fa-arrow-circle-right xl:text-[1.5em] 2xl:text-[2.2em]"/> 
                </div>

                <p className="text-[1.1em] xl:text-[1.5em] 2xl:text-[2em] font-[600]">{data.btn}</p>
            </span>
        </div>
    )
}