import { cafri, cea, cida, cvw, ehafri, glbh, int, kip, prins, space, trip, vaitor, ymap } from "../../assets/assets"

export const Brandswork = ()=> {

    return (
        <section className="w-full h-fit lg:h[100vh] py-[100px]">
            <div className="container mx-auto flex flex-col items-center gap-[50px] text-center">
                <h3 className="text-[2.5em] md:text-[3em] lg:text-[4em] text-velion-800 font-[700] leadiing-[1.4em]">Companies our team has work for</h3>
                <div className="flex flex-wrap items-center justify-center gap-[70px]">
                    {
                        [cea,glbh,space,kip, ymap,ehafri,prins,cvw,cafri,cida,int, trip, vaitor].map((item,index)=> (
                           <div className="cols-span-1 max-w-[100px] lg:max-w-[220px]">
                                <img src={item} alt={`brand-${index}`} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}