import { useHistory } from "react-router"
import { servBg, servTab } from "../../assets/assets"
import { Back } from "../../components/common/button"
import { DevopsModel, HstModel, ServicesModel } from "../../data/model"
import { devopsPage, servProcess, aiData, aiData2 } from "../../data/data"


export const Servicehero = (props:any)=> {
    
    return (
        <section className={`w-full lg:h-fit flex flex-col items-center pb-[0px]`}>
            {/* bg-[#E7F9EE] */}
            <div className="mx-auto relative w-full h-[800px] lg:h-[55em] flex items-center justify-center lg:p-0">
                    <span className="mx-auto -mb-24 flex flex-col text-center items-center gap-[10px] lg:max-w-[80vw] my-auto">
                        <h4 className={`mb-10 w-full text-[2.5em] md:text-[3em] lg:text-[3.5em] max-w-3xl font-semibold lg:font-[700] text-neutral-900 leading-[1.2em] capitalize`}>
                            Transform your business with cutting edge technology solution</h4>
                        <p className="my-0 w-full text-[1em] lg:text-[1.4em] max-w-4xl pt-3 pb-5 font-[400] text-gray-800">
                        We delivers innovative technology solutions that help businesses overcome complex challenges, providing our clients with a competitive edge and unparalleled value
                        </p>
                    </span>
                
                    <img 
                    data-aos='fade-up'
                    data-aos-duration="1400"
                    data-aos-delay="100"
                    data-aos-once="true"
                     src={servBg} alt="chains" loading="eager" className="hidden absolute  md:flex lg:flex w-full  right-0 left-0 bottom-[-150px] z-10" />
            </div>
            <img src={servTab} alt="devops" className="  md:flex lg:flex w-[95vw] md:w-[80vw]  lg:w-[70vw] right-0 left-0 mt-[-150px] md:mt-[-17em] z-20" />
            
        </section>
    )
}

export const Serviceshero = (props:any)=> {
    const nav = useHistory()
    return (
        <section className={`w-full mx-auto 'bg-[#FAFAFA]' pt-32 pb-8 h-fit flex md:items-center`}>
          
            <div 
            // aos fade up once 
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="100"

            
            className={`mx-auto pt-16 md:pt-6 lg:pt-0 w-full container pb-0 flex flex-col md:flex-row items-center md:justify-around  ${props.index % 2 === 0 ? ' md:flex-row':'md:flex-row-reverse'}`}>
                <div className="w-full flex items-center rounded-xl justify-center lg:w-[500px] h-fit lg:h-[700px] overflow-hidden">
                    <img src={props.img} className=" w-full h-full md:w-[480px] md:h-[470px]  rounded-md" alt="service"/>
                </div>
                <div className="flex pt-3 flex-col lg:w-[500px] text-left">
                    {/* prevoius button  */}

                    {
                     <Back click={()=> nav.goBack()} />
                    }

                    <span className="flex flex-col pt-3 text-left">  
                        <h5 className={` font-serif text-4xl 2xl:text-5xl mb-3 lg:mb-6 font-bold  `} >{props.title}</h5>
                        <p className="text-sm font-medium text-[#858a8f] lg:text-lg leading-7 ">{props.subtitle}</p>
                    </span>
                  
                </div>
            </div>
          
        </section>
    )
}

export const Servicesben = (props:any)=> {
    return (
        <section className={`w-full  h-fit pt-[100px] py-[50px]`}>
            <div 
             data-aos='fade-up'
             data-aos-duration="1000"
             data-aos-delay="100"
             data-aos-once="true"
             className="container w-full mx-auto flex flex-col gap-[50px]">
                <span   className="w-full flex flex-col items-center text-center">
                    <h4 className={`text-[2em] md:text-[3em] xl:text-[3.75em] font-[700] leading-[1.4em] ${props.color} `}>{props.title}</h4>
                    <p className="text-[1em] md:text-[1.3em] xl:text-[1.8em] font-[400] text-gray-800 leading-[1.4em]">{props.subtitle}</p>
                </span>
                <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 grid-rows-2 gap-[20px]">
                        {
                            (props.data ?? []).map((item:any,index:number)=> ((
                                <span key={index} className={`w-full flex flex-col gap-[10px] items-center text-center ${props.bg} px-[20px] py-[35px]`}>
                                        <img src={item.image} className="mb-[5px]" alt="services"/>
                                        <p className={`my-0 text-[1.2em] font-[700] ${props.color} leading-[1.2em]`}>{item.title}</p>
                                        <p className="my-0 text-[1em] font-[400] text-gray-800 leading-[1.4em]">{item.subtitle}</p>
                                </span>
                            )))
                        }
                </div>
            </div>

        </section>
    )
}



export const ServicesApp = (props:any)=> {
    return (
        <section className="w-full  relative flex flex-col items-center gap-[50px] py-[100px]">
            <span className={props.box +` flex flex-col items-center `}>
            {props.title && <h4 className={`my-0 mx-auto text-center text-velion-700 text-[2em] md:text-[3em] xl:text-5xl font-[700] lg:max-w-[70vw]`}>
                {props.title}</h4>}

            {props.image && <img className={props.addOns2} src={props.image} alt="services" />}
            </span>
           
            <div className={` mx-auto w-full h-fit py-16 bg-velion-700 text-white ${props.addOns}`}>
                <div className="container h-fit w-full mx-auto flex flex-col gap-[50px]">
                    
                    <div className={`w-full grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-3   md:gap-16  2xl:gap-x-28 2xl:gap-20`}>
                            {
                                (props.data ?? []).map((item:any,index:number)=> ((
                                    <span key={index} className={` ${props.gridAdd} w-auto 2xl:w-96  flex items-start text-left`}>
                                            <img src={item.image} className="w-[65px] md:w-[80px] 2xl:w-28 " alt="services"/>
                                            <span className="flex flex-col px-2 max-w-[80vw] md:w-full">
                                                <p className={`my-0 mb-4 text-[1em] md:text-base 2xl:text-lg font-medium `}>{item.title}</p>
                                               
                                                <p className="my-0 text-white/80 text-[.9em] md:text-sm font-[400] 2xl:text-base pt-1 leading-[1.5em]">{item.subtitle}</p>
                                            </span>
                                    </span>
                                )))
                            }
                    </div>
                </div>
            </div>
        </section>
    )
}

// 
export const Servicespro = (props:any)=> {
    return (
        <section className="w-full   flex flex-col items-center pb-24 ">
            {
                props.title && <span className="text-center w-[80%] lg:w-[75vw] mx-auto flex flex-col pb-32">
                    <h4 className={`my-0 text-[2em] md:text-[3em] xl:text-[3.75em] font-[700] ${props.tcolor}`}>{props.title}</h4>
                    <p className="my-0 text-[1em] lg:text-[1.8em] font-400 text-neutral-800" >{props.subtitle}</p>
                </span>
            }
            <span className="w-full flex flex-col gap-[0px]">
                {
                    props.data.map((item:HstModel,index:number)=> (
                        <Singleservpro key={index} data={item} index={index} />
                    ))
                }
            </span>
            
        </section>
    )
}

const Singleservpro = (props:any)=> {
    return (
        <div className={`w-full mx-auto ${props.index % 2 === 0 ? 'bg-white':'bg-[#f5f6fa]'}`}>
            <div
            // data-aos="fade-up"
            // data-aos-duration="1000"
            // data-aos-delay="100"

             className={`mx-auto py-14 w-full container pb-0  flex items-center justify-between flex-col  ${props.index % 2 === 0 ? ' md:flex-row':'md:flex-row-reverse'}`}>
                <div className="lg:w-[600px] pb-6 md:pb-0 lg:h-[600px] px-3 md:w-1/2 justify-center items-center flex  overflow-hidden">
                    <img src={props.data.image} className="w-auto h-[430px] rounded-full" alt="service"/>
                </div>
                <span className="flex flex-col md:w-1/2  py-10 md:py-2 text-left">
                    <h5 className="text-3xl lg:text-4xl lg:leading-snug lg:text-[40px] xl:text-[42px] tracking-wider  mb-3 lg:mb-5 font-bold text-black" >{props.data.title}</h5>
                    <p className="text-sm font-medium text-[#858a8f] xl:text-lg text lg:text-base leading-7 "> {props.data.subtitle}</p>
                </span>
            </div>
        </div>
    )
}



export const AllServices = (props:any)=> {
    return (
        <section className="w-full flex flex-col items-center py-24 md:py-4">
            {
                servProcess.map((item:ServicesModel,index:number)=> (
                    <Singleserv key={index} data={item} index={index} />
                ))
            }
        </section>
    )
}

export const AllDevops = () => {
    return (
        <section className="w-full flex flex-col items-center justify-center md:py-4">
            {
                devopsPage.map((item:DevopsModel, index:number) => (
                    <AllDevsSingle key={index} data={item} index={index} />
                ))
            }
        </section>
    )
}

export const AiData1 = () => {
    return (
        <section className="w-full flex flex-col items-center justify-center md:py-4">
            {
                aiData.map((item:DevopsModel, index:number) => (
                    <AllDevsSingle key={index} data={item} index={index} />
                ))
            }
        </section>
    )
}

export const AiData2 = () => {
    return (
        <section className="w-full flex flex-col pb-10 items-center justify-center md:py-4">
            {
                aiData2.map((item:DevopsModel, index:number) => (
                    <AllDevsSingle key={index} data={item} index={index} />
                ))
            }
        </section>
    )
}

const AllDevsSingle = (props:any)=> {
    return (
        <div className={`w-full pt-9  md:py-0 xl: pb-9 mx-auto ${props.index % 2 === 0 ? 'bg-white':'bg-[#f5f6fa]'}`}>
            <div 
            // aos fade up once 
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="100"
            
            
            className={`mx-auto w-full container pb-0 flex flex-col md:flex-row items-center gap-[30px] md:gap-[65px] md:justify-around  ${props.index % 2 === 0 ? ' md:flex-row':'md:flex-row-reverse'}`}>
                <div className="w-full md:w-1/2 flex items-center justify-center h-[353px] lg:h-[700px] overflow-hidden">
                    <img src={props.data.image} className="rounded-full md:w-auto h-auto " alt="service"/>
                </div>
             
                <div
                
                 className="flex flex-col w-full md:w-1/2 text-left">
                    <span className="flex flex-col  text-left">  
                        <h5 className={` text-3xl font-bold md:text-4xl 2xl:text-5xl mb-3 lg:mb-5 2xl:font-bold `} >{props.data.title}</h5>
                        <p className="text-sm  font-medium text-[#858a8f] lg:text-lg leading-7 ">{props.data.description}</p>
                    </span>
                    
                </div>
            </div>
        </div>
    )
}



const Singleserv = (props:any)=> {
    const nav = useHistory()
    return (
        <div className={`w-full py-[60px] md:py-0 mx-auto ${props.index % 2 === 0 ? 'bg-white':'bg-[#FAFAFA]'}`}>
            <div 
            // aos fade up once 
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="100"

            
            className={`mx-auto w-full container pb-0 flex flex-col md:flex-row items-center gap-[30px] md:gap-[65px] md:justify-around  ${props.index % 2 === 0 ? ' md:flex-row':'md:flex-row-reverse'}`}>
                <div className="w-[100%] flex items-center rounded-xl justify-center lg:w-[400px] h-[353px] lg:h-[700px] overflow-hidden">
                    <img src={props.data.image} className=" md:w-auto h-auto  rounded-md" alt="service"/>
                </div>
                <div
                
                 className="flex flex-col lg:max-w-[500px] text-left">
                    <span className="flex flex-col gap-[15px]  text-left">  
                        <h5 className={` text-3xl md:text-4xl lg:text-5xl mb-3 lg:mb-5 font-bold  ${props.data.tcolor}`} >{props.data.title}</h5>
                        <p className="text-sm font-medium text-[#858a8f] lg:text-lg leading-7 mb-4">{props.data.subtitle}</p>
                    </span>
                    <button onClick={()=> nav.push(props.data.link)} className="w-fit flex gap-[10px] items-center mt-3 bg-velion-700 rounded-full px-[20px]  py-[10px] text-white text-[1em] capitalize">
                        LEARN MORE
                        <i className="fas fa-arrow-right" />
                    </button>
                </div>
            </div>
        </div>
    )
}
