import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router';
import { Homepage } from './pages/homepage';
import { Error } from './pages/error';
import { allRoute } from './data/routes';
import './App.css';

import AOS from "aos";

import "aos/dist/aos.css";

function App() {

  useEffect(() => {
    AOS.init({
      easing: "ease-out-cubic",
      once: false,
      offset: 50,
    });
  }, []);

  
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Homepage} /> 
        {
          allRoute.map((item,index)=> (
            <Route exact path={item.path} component={item.component} /> 
          ))
        }
        <Route component={Error} />
      </Switch>
      
    </div>
  );
}

export default App;
