import { Abouthero } from "../containers/about/hero";
import { Teamabout } from "../containers/about/team";
import { Values } from "../containers/about/values";
import { Weblayout } from "../components/common/weblayout";
import { Reachout } from "../containers/contact/sendmessage";
import { Brandswork } from "../containers/about/brands";

export const Aboutus = ()=> {

    return (
        <Weblayout>
            <Abouthero/>
            <Values/>
            <Teamabout/>
            <Brandswork/>
            <Reachout/>
        </Weblayout>
    )
}