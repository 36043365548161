import React from "react"
// import Errorsvg from "../assets/svgs/error.svg"
import { Bigtitle } from "../components/common/bigtitle"
import { Button } from "../components/common/button"
import { useHistory } from "react-router"
// import { NavLink } from "react-router-dom";
export const Error = ()=> {

    const nav = useHistory()
    return (
        <div className="flex h-[100vh] justify-center items-center">
            <div className="flex flex-col gap-[25px] items-center">
                    {/* <img className="md:w-[450px]" src={Errorsvg} alt="Error-404-svg"/> */}
                    <Bigtitle
                        title="Oops"
                        subtitle="We do not have the page you are currently looking for, maybe it was removed or renamed."
                        classes="md:max-w-[450px]"
                    />
                    <div className="w-[250px] bg-velion-300 h-fit pb-[5px] rounded-[10px]">
                        <Button name="Back to Homepage" click={()=> nav.push('/')} />
                    </div>
                    {/* <NavLink className="error-404-btn" to="/" style={{textDecoration:'none'}}> Back To Home </NavLink> */}
            </div>

        </div>
    )
}