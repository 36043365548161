export const Input = (props:any)=> {

    return (
        <span className={`w-full  ${props.icon && 'relative'}`}>
            <input
            className={`bg-grey-400 py-[16px] px-[20px] text-deepbrown outline-none
            border border-midgrey w-full rounded-[5px]
            ${props.addOns} ${props.icon && 'pl-[50px] relative'}`}
            placeholder={props.placeholder} 
            type={props.type}
            name={props.name}
        />
         {props.icon && <i className={ `absolute top-0 bottom-0 h-fit my-auto left-[15px] text-[1.5em] text-velion-600  ${props.icon}`} />}
        </span>
        
    )
}

export const Textarea = (props:any)=> {

    return (
        <textarea
            className="bg-grey-400 py-[20px] pb-[160px] px-[15px] text-deepbrown outline-none
            border border-midgrey w-full rounded-[5px]
            "
            placeholder={props.placeholder}


            name={props.name}
            
        />
    )
}

export const SelectOptions = (props:any)=> {
    
        return (
            <select
                className="bg-grey-400 py-[20px] pb-[160px] px-[15px] text-deepbrown outline-none
                border border-midgrey w-full rounded-[5px]
                "
                placeholder={props.placeholder}

                name={props.name}
                value={props.value}

            />
        )
    }

