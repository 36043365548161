import React from 'react'

// type contentProps = {
//   content: {
//     id: string;
//     title: string;
//     content: string;
//     content2: string;
//     image: string;
//     img2: string;
//     date: string;
//     description: string;
//   }[]
// };

function Herosection({ content }: any) {
  return (
    <section
      className="relative w-full h-full bg-transparent md:bg-[url('/src/assets/images/blog/blogTbanner.png')] bg-cover bg-center bg-no-repeat"
    >
      <div
        className="relative mx-auto py-52 max-w-screen-xl px-4 sm:px-6 lg:flex lg:h-screen lg:items-center lg:justify-start lg:px-8"
      >
        <div className="max-w-xl text-start text-white pb-24 ltr:sm:text-left rtl:sm:text-right">
          <h1 className="text-4xl font-mono  mb-3 font-semibold sm:text-5xl">
            {content.title}
          </h1>

          <p className="mt-4 max-w-lg text-base leading-relaxed">
            {content.description}
          </p>
        </div>
      </div>
    </section>
  );
}

// export default Herosection;
//           {content.title}
  
//         </h1>
  
//         <p className="mt-4 max-w-lg text-base leading-relaxed">
//           {content.description}
//         </p>
  
        
//       </div>
//     </div>
//   </section>
//   )
// }

export default Herosection