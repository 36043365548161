import softdev from "../assets/svgs/softdev.svg"
import cloud from "../assets/svgs/cloudinfra.svg"
import datas from "../assets/svgs/datas.svg"

// import tech from "../assets/svgs/tech.svg"
// import finance from "../assets/svgs/finance.svg"
// import health from "../assets/svgs/health.svg"
// import retail from "../assets/svgs/retail.svg"
// import trans from "../assets/svgs/transportation.svg"
// import energy from "../assets/svgs/energy.svg"

import jide from "../assets/images/jide.png"
import atlassian from "../assets/images/attlasian.png"

import mail from "../assets/svgs/mail.svg"
import linked from "../assets/svgs/linked.svg"
import mobile from "../assets/svgs/mobile.svg"

import uiux from "../assets/images/uiux.png"
import febe from "../assets/images/febe.png"
import pmanag from "../assets/images/pmanag.png"
import ml from "../assets/images/ml.png"
import softtest from "../assets/images/softtest.png"

import lab from "../assets/images/lab.png"
import duedili from "../assets/images/duedili.png"
// import softtest from "../assets/images/softtest.png"
// import uiux from "../assets/images/attlasian.png"

import { ddteam, teamexp, projm, cus,getc,getco, den, jeph, del,
    devcloud,devalert, dset,devcont,devmodel,devinfra,
    dev1, dev2, dev3,dev4,dev5,dev6, des1, des2, des3,des4,des5,
    sstra,sarchi,scons,sdcons,stech,stest, aichip, aibiz,ailoc, aiviz, ailang,
    aimodel, ai1,ai2,ai3,ai4,ai5,ai6,sdes1, sdes2, sdes3,sdes4,sdes5,sdes6,cons1,cons2,cons3,cons4,cons5,cons6,serv5,serv11,
    tech,care,trans,fin,retail,energy, port,uk,bra,certi, serv7, serv8, serv9, 
    // serv10,
     startup1, startup2, startup3, startup4,
    //  img2,
     devss,
     devop1,
     devop2,
     devop,
    //  img,
     aidataIOT,
     aidataBig,
    //  aidataArtificial,
     aidataML,
     aidataLast,
     aidatapimg,
     train1,
     train2,
     locat,
     graph,
     group2,
     group1,
     group3,
     group4,
     group5,
     group6
} from "../assets/assets"

import { AidataModel, ContactsModel, DevopsModel, Footer, HstModel, Industries, ServicesModel, TeamModel, TestimonialModel, Whatwemodel } from "./model"

export const Links =[
    {
        name:'What we do',
        link:'/services',
        dropdown:true
    },
    {
        name:'Who we are',
        link:'/aboutus'
    },
    {
        name:'Blog',
        link:'https://velionit.substack.com/'
    }
    // {
    //     name:'Contact Us',
    //     link:'/contactus'
    // }
]

export const SoftwareServices =[
    {
        title: "Web, Mobile & Desktop Application Development",
        subtitle: "Our experienced web developers can help you deliver unique, fully-responsive websites, mobile apps and custom applications for complex use cases. We work across a diverse set of technologies including: Python, Ruby, Javascript frameworks, .Net, HTML, CSS and React, Angular, and others for the web, and iOS and Android apps using React Native, Phonegap, and hybrid development.",
        image: group1,
        bgcolor: 'bg-[#F0F5FF]',
    },

    {
        title: "Custom Enterprise Solution Development",
        subtitle: "We build custom software applications tailored to your business. Our team of experts will work with you to understand your unique requirements and deliver a solution that meets your needs. We use the latest technologies and best practices to ensure your software is scalable, secure, and easy to maintain.",
        image: group2
        ,bgcolor: 'bg-[#FFEBCC]'
    },
        {
            title: "Quality Assurance and Software Testing",
            subtitle: "Velion’s talented and dedicated quality assurance (QA) teams can provide scripted and manual software testing services to ensure end-to-end quality throughout your solution. Using the most modern and efficient techniques, our QA teams give clients the confidence that their success won’t be undermined by quality issues.",
            image: group3,
            bgcolor: 'bg-[#F0DAFB]'

        },
        {
            title: "Product Management",
            subtitle: "Our product managers drive the success of our software development efforts. Whether working on a simple app or an enterprise-wide initiative, our PMs bring exceptional communication skills and the ability to align goals across diverse groups of stakeholders.",
            image: group4,
            bgcolor: 'bg-[#D9F9E3]'
        },
        {
            title: "SEO & Digital Marketing",
            subtitle: " Our SEO and digital marketing services help businesses improve their online visibility and drive traffic to their websites. Our team of experts will work with you to develop a customized strategy that meets your unique needs and goals.",
            image: group5,
            bgcolor: 'bg-[#FFE0ED]'
        },

        {
            title: "Brand Strategy & Design",
            subtitle: "Our brand strategy and design services help businesses develop a strong brand identity that resonates with their target audience. Our team of experts will work with you to create a brand strategy that aligns with your business goals and values.",
            image: group6,
            bgcolor: 'bg-[#F0F5FF]'
        

        }

    
];
export const serviceLinks =[

    {
        name:'AI & Data Services​',
        link:'/services/ai-data-services',
    },
    // {
    //     name:'Software Development',
    //     link:'/services/softwaredev'
    // },
    {
        name:'Software Services',
        link:'/services/software-services'
    },
    {
        name:'Dedicated Teams​',
        link:'/services/dedicated-teams',
    },
    {
        name:'Startup and MVP Solutions',
        link:'/services/startup-solutions'
    },
    {
        name:'Technical Training',
        link:'/services/technical-trainings'
    }
]

export const whatwe:Whatwemodel[] = [
    {
        image:softdev,
        title:"AI & Data Services​",
        subtitle:'Unlock your business’s full potential with expert artificial intelligence and data services.',
        bcolor:'bg-[#347295]',
        tcolor:'text-[#347295]',
        link:'/services/ai-data-services'
    },
    {
        image:softdev,
        title:"Software Services",
        subtitle:'Customized software development solutions to accelerate your business.',
        bcolor:'bg-[#FF9900]',
        tcolor:'text-[#FF9900]',
        link:'/services/software-services'
    },
    // {
    //     image:softdev,
    //     title:"Software Consulting",
    //     subtitle:'Maximize productivity with tailored software consulting by expert professionals.',
    //     bcolor:'bg-[#DB3236]',
    //     tcolor:'text-[#DB3236]',
    //     link:'/services/softwareconsult'
    // },
    {
        image:datas,
        title:"Dedicated Teams",
        subtitle:'Streamline your tech and time to delivery of product with our Dedicated Teams.',
        bcolor:'bg-[#68A703]',
        tcolor:'text-[#68A703]',
        link:'/services/dedicated-teams'
    },
    {
        image:cloud,
        title:"Startup & MVP Solutions",
        subtitle:'Achieve startup success with our Startup and MVP services. Minimize costs and risks.',
        bcolor:`bg-[#CE1E5B]`,
        tcolor:`text-[#CE1E5B]`,
        link:'/services/startup-solutions'
    },
    {
        image:cloud,
        title:"Technical Training​",
        subtitle:'Advance your technological skills with customized training and expert guidance.',
        bcolor:'bg-[#4885ED]',
        tcolor:`text-[#4885ED]`,
        link:'/services/technical-trainings'
    }
]


export const IndsOne:Industries[]=[
    {
        image:tech,
        title:'Technology'
    },
    {
        image:care,
        title:'Health Care'
    },
    {
        image:trans,
        title:'Transportation'
    }
]

export const IndsTwo:Industries[]=[

    {
        image:fin,
        title:'Finance'
    },
    {
        image:retail,
        title:'retail'
    },
    {
        image:energy,
        title:'Energy'
    }
]

export const linksFooter:Footer[] = [
    {
        title:'Navigation',
        subs:[
            {
                name:'What we do',
                link:'/services',
                dropdown:true
            },
            {
                name:'Who we are',
                link:'/aboutus'
            },
            {
                name:'Contact us',
                link:'/contactus'
            },
        ]
    },
    {
        title:'Resources',
        subs:[
            {
                name:'Blogs',
                link:'/services',
                dropdown:true
            },
            // {
            //     name:'News Update',
            //     link:'/aboutus'
            // },
            // {
            //     name:'Blog',
            //     link:'/blog'
            // }
        ]
    }
]

export const testimonial:TestimonialModel[] = [
    {
        id:0,
        image:jide,
        body:`"Brass, with the sub-accounts feature,
        makes it easy to segment our cash and in turn
        optimise it for targeted goals."`,
        logo:atlassian

    },
    {
        id:1,
        image:jide,
        body:`"Gooogle, with the sub-accounts feature,
        makes it easy to segment our cash and in turn
        optimise it for targeted goals."`,
        logo:atlassian

    },
    {
        id:2,
        image:jide,
        body:`"Facebook, with the sub-accounts feature,
        makes it easy to segment our cash and in turn
        optimise it for targeted goals."`,
        logo:atlassian

    }
]

export const team:TeamModel[] = [
    {
        id:0,
        image:den,
        cert:[port],
        link:"https://www.linkedin.com/in/dennis-irorere/",
        name:'Dennis Irorere',
        title:'Managing Partner - Executive Officer',
        body:`Co-founder of Velion Enterprise; Research Fellow USAID; 8+ years Data & Management; MSc.IMS @ NOVA IMS `,
    },
    {
        id:1,
        image:jeph,
        cert:[uk],
        link:"https://www.linkedin.com/in/jephtahuche/",
        name:'Jephtah Uche',
        title:'Managing Partner – Technology Officer',
        body:`Co-founder of Velion Enterprise; Member of Forbes Technology Council; 8+ years Product & Engineering; Ex-CTO Kippa`,
    },
    {
        id:1,
        image:del,
        cert:[bra,certi],
        link:"https://www.linkedin.com/in/deliane-duarte-pmp/",
        name:'Deliane Amaro Duarte',
        title:'Senior Partner – Brazil',
        body:`10+ years Contract & Project Management; Agile Professional Certified; MBA in Marketing Intelligence @ FIA-USP;
        Msc.IMS @ NOVA IMS`,
    }
]

export const ourValues = [
    {
        title:'Innovation',
        subtitle:'Collaboration is in our DNA. It guides our workspace design, the way we put together lean, flat teams, and how we think about the best talent. The toughest problems won’t be solved alone, so we take pride in working together. (It’s also why we always give back to our communities and each other.)'
    },
    {
        title:'EXCELLENCE ',
        subtitle:'When the bar is set to world-class - every detail matters. We take pride in delivering our best work through dedication, drive, and thoughtfulness. We work hard so we can bring the big picture to life on time, every time.'
    },
    {
        title:'Collaboration',
        subtitle:'Using the power of technology would not be possible without the endless potential of human intelligence. The incredible results we achieve are a joint product of outstanding talent, human intellectual property, and the latest technology, all combined toward a common goal of creating meaningful value.'
    },
    {
        title:'integrity ',
        subtitle:'This isn’t about sprinting towards the end of a contract. This is about driving technology forward and building the foundation of long-term partnerships so we can do work that matters.'
    },
    {
        title:'growth ',
        subtitle:'We are lifelong learners. Our growth mindset means that we are always looking for ways to be better. We track things like happiness and motivation so we can adapt. We learn from working with each other, new teams, new technology or in new cities.'
    }
]

export const contacts:ContactsModel[] =[
    {
        image:mail,
        type:"email",
        title:'Email Us',
        link:"info@velionit.com",
        subtitle:'Ask us a question by email and we will respond within a few days.',
        btn:'Leave a message'
    },
    {
        image:linked,
        type:"linkedIn",
        title:'Connect With Us',
        link:"https://www.linkedin.com/company/85649390",
        subtitle:`You can connect with us on linkedin and
        follow our post.`,
        btn:'Our LinkedIn'
    },
    {
        image:mobile,
        type:"call",
        title:'Call Us Now',
        link:"+2348149835443",
        subtitle:`For quick response and consultations`,
        btn:'Call Us'
    }
]

export const services : ServicesModel[] = [
    {
        id:1,
        title:'Front-end, Back-end & Mobile Development',
        subtitle:`
            Our experienced web developers can help you deliver unique, fully-responsive websites, mobile apps and custom applications for complex use cases. We work across a diverse set of technologies including: Python, Ruby, Javascript frameworks, .Net, HTML, CSS and React, Angular, and others for the web, and iOS and Android apps using React Native, Phonegap, and hybrid development.
        `,
        image:febe
    },
    {
        id:2,
        title:'Data Science & Machine Learning',
        subtitle:`
        Before we write a single line of code, our engineers and business analysts work with your team to identify goals, create requirements, outline technical specifications, and build prototypes of the finished product. This step of the process ensures all teams are aligned and set up for success.
        `,
        image:ml
    },
    {
        id:3,
        title:'UX & UI Design',
        subtitle:`
            Our experienced web developers can help you deliver unique, fully-responsive websites, mobile apps and custom applications for complex use cases. We work across a diverse set of technologies including: Python, Ruby, Javascript frameworks, .Net, HTML, CSS and React, Angular, and others for the web, and iOS and Android apps using React Native, Phonegap, and hybrid development.
        `,
        image:uiux
    },

    {
        id:4,
        title:'Product Management',
        subtitle:`
        Our product managers drive the success of our software development efforts. Whether working on a simple app or an enterprise-wide initiative, our PMs bring exceptional communication skills and the ability to align goals across diverse groups of stakeholders.
        `,
        image:pmanag
    },
    {
        id:5,
        title:'Software Testing& QA',
        subtitle:`
        Velion’s talented and dedicated quality assurance (QA) teams can provide scripted and manual software testing services to ensure end-to-end quality throughout your solution. Using the most modern and efficient techniques, our QA teams give clients the confidence that their success won’t be undermined by quality issues.
        `,
        image:softtest
    }
] 

export const ctos : ServicesModel[] = [
    {
        id:1,
        title:'Technical Due Diligence',
        subtitle:`
            Before we write a single line of code, our engineers and business analysts work with your team to identify goals, create requirements, outline technical specifications, and build prototypes of the finished product. This step of the process ensures all teams are aligned and set up for success.Before we write a single line of code, our engineers and business analysts work with your team to identify goals, create requirements, outline technical specifications, and build prototypes of the finished product. This step of the process ensures all teams are aligned and set up for success.
            Following the Google Ventures design sprint model, our teams build, refine, and test prototypes to identify the optimal product plan to deliver real value at launch.
        `,
        image:duedili
    },
    {
        id:2,
        title:'Incubation Labs',
        subtitle:`
            Before we write a single line of code, our engineers and business analysts work with your team to identify goals, create requirements, outline technical specifications, and build prototypes of the finished product. This step of the process ensures all teams are aligned and set up for success.Before we write a single line of code, our engineers and business analysts work with your team to identify goals, create requirements, outline technical specifications, and build prototypes of the finished product. This step of the process ensures all teams are aligned and set up for success.
        `,
        image:lab
    },
    {
        id:3,
        title:'Digital Transformation & Strategy',
        subtitle:`
            We help clients backtest and validate product assumptions through design, proof of concept (PoC) and minimum viable product (MVP) development, qualitative user testing, and quantitative market evidence. This significantly reduces the development time, lowers investment risk, and facilitates access to funding.
        `,
        image:uiux
    }
] 

export const devops : ServicesModel[] = [
    {
        title:'Cloud Architecture',
        subtitle:`
        Design and implement scalable, flexible and secure cloud infrastructure.
        `,
        image:devcloud
    },
    {
        title:'Continuous Integration and Deployment',
        subtitle:`
            Automate the process of building, testing, and deploying software.
        `,
        image:dset
    },
    {
        title:'Containerization and Orchestration',
        subtitle:`
        Deploy and manage containerized applications with Kubernetes
        `,
        image:devcont
    },
    {
        title:'Monitoring and Alerting',
        subtitle:`
        Design and implement scalable, flexible and secure cloud infrastructure.
        `,
        image:devalert
    },
    {
        title:'Model Deployment and Management',
        subtitle:`
            Automate the process of building, testing, and deploying software.
        `,
        image:devmodel
    },
    {
        title:'ML Infrastructure Management',
        subtitle:`
        Deploy and manage containerized applications with Kubernetes
        `,
        image:devinfra
    }
]

export const devopsApp : ServicesModel[] = [
    {
        title:'System Audit',
        subtitle:`
            Assessing the current state of the organization’s technology infrastructure, development processes and team skills to identify areas for improvement
        `,
        image:dev1
    },
    {
        title:'Goal & Metric',
        subtitle:`
        Establishing clear goals and metrics for the MLOps and DevOPs implementation.
        `,
        image:dev2
    },
    {
        title:'Design Roadmap',
        subtitle:`
            Creating a roadmap for the implementation that includes the necessary tools, processes, and training for the team.
        `,
        image:dev3
    },
    {
        title:'CI/CD',
        subtitle:`
            Implementing continuous integration and continuous delivery (CI/CD) pipelines for automated software deployment.
        `,
        image:dev4
    },
    {
        title:'Infrastructure as Code:',
        subtitle:`
            Setting up infrastructure as code to manage and provision infrastructure resources efficiently and consistently.
        `,
        image:dev5
    },
    {
        title:'Monitoring and Alerting',
        subtitle:`
        Deploy and manage containerized applications with Kubernetes
        `,
        image:dev6
    }
] 

export const softdevs : ServicesModel[] = [
    {
        title:'Application Services',
        subtitle:`
        From ideation to development, we create tailored applications that meet your specific business requirements, enhancing productivity and user satisfaction.
        `,
        image:sdes1
    },
    {
        title:'Blockchain Development',
        subtitle:`
        Leverage the power of blockchain technology for secure and transparent transactions, enabling trust and efficiency in your business operations.
        `,
        image:sdes2
    },
    {
        title:'Customer Software Development',
        subtitle:`
        We deliver customer software solutions that automate processes, improve operational efficiency, and drive business growth, tailored precisely to your unique needs
        `,
        image:sdes3
    },
    {
        title:'Cloud Solutions',
        subtitle:`
            Transform your business with scalable and cost effective cloud-based solutions that provide flexibility, reliability, and data security
        `,
        image:sdes4
    },
    {
        title:'Mobile App Development',
        subtitle:`
        Reach your customers on the go with captivation and feature-rich mobile applications for IOS and android platform
        `,
        image:sdes5
    },
    {
        title:'UI/UX Design',
        subtitle:`
            Our expert designers craft intuitive and visually captivating user interfaces, ensuring seamless user experiences that drive engagement and loyalty
        `,
        image:sdes6
    }
]

export const softdevsApp : ServicesModel[] = [
    {
        title:'Requirement Gathering & Planning',
        subtitle:`
        Understanding the client’s needs and creating a comprehensive plan outlining project scope, timeline, resource allocation, and development approachUnderstanding the client’s needs and creating a comprehensive plan outlining project scope, timeline, resource allocation, and development approach
        `,
        image:des1
    },
    {
        title:'Design',
        subtitle:`
        We develop the software architecture, database structure, user interface, and other design elements using our understanding of the business
        `,
        image:des2
    },
    {
        title:'Development',
        subtitle:`
        We write and test the actual codes to implement the desired functionality and features
        `,
        image:des3
    },
    {
        title:'Testing',
        subtitle:`
        Conducting rigorous testing to identify and fix any bugs or issues and ensure the software functions as intended
        `,
        image:des4
    },
    {
        title:'Deployment',
        subtitle:`
        Deploying the software to the production environment and making it available for users
        `,
        image:des5
    }
] 

export const howWe:HstModel[] = [
    {
        image:teamexp,
        title:"Dedicated Teams​",
        subtitle:'Our approach to project management involves building dedicated teams to ensure consistent quality and effective communication. Our experts are fully committed to helping you achieve your business objectives.',
    },
    {
        image:ddteam,
        title:"Team Extension​",
        subtitle:'Our team expansion services help businesses extend their capabilities by seamlessly integrating our experts with their in-house teams. This enables us to build a collaborative environment and achieve project goals together.',
    },
    {
        image:projm,
        title:"Project-based Model",
        subtitle:'Our project-based development approach at Velion involves delivering customized solutions that meet unique business needs, while maintaining high quality and staying within budget and timeline constraints.',
    }
]

export const gStart:HstModel[] = [
    {
        image:cus,
        title:"1. Contact Us​",
        subtitle:'Fill out the contact form protected by NDA, book a calendar and schedule a Zoom Meeting with our experts.',
        tcolor:'text-[#16AB5E]'
    },
    {
        image:getc,
        title:"2. Get a Consultation​",
        subtitle:'Get on a call with our team to know the feasibility of your project idea.',
        tcolor:'text-[#FF5F08]'
    },
    {
        image:getco,
        title:"3. Get a Cost Estimate​",
        subtitle:'Based on the project requirements, we share a project proposal with budget and timeline estimates.',
        tcolor:'text-[#FFB900]'
    },
    {
        image: projm,
        title:"4. Project Kickoff​",
        subtitle:'Once the project is signed, we bring together a team from a range of disciplines to kick start your project.',
        tcolor:'text-[#FA0F00]'
    }
]

// services

export const devopsPage : DevopsModel[]  = [
    {
        id: 1,
        title: "Providing Technical Expertise",
        description: "We offer consulting services for enterprises and investment firms based on our successful delivery of hundreds of digital projects and solutions for the world’s most demanding clients.",
        image: devss,

    },
    {
        id: 2,
        title: "Technical Due Diligence",
        description: "Velion’s Due Diligence audit uses a tried-and-tested framework to deliver an in-depth report on people, product, and process capabilities, providing actionable insights into investment and growth opportunities and risks.",
        image: devop,

    },
    {
        id: 3,
        title: "Innovation Labs",
        description: " Our Innovation Lab services are designed to help organizations cultivate new ideas and develop long-term strategies. Innovation Labs encourage creative, out-of-the-box solutions to systemic digital problems. They are a way to get teams to think outside the status quo.",
        image: devop2,

    },
    {
        id: 4,
        title: "Digital Transformation & Strategy",
        description: " Velion supports organizations’ digital transformation and business strategy efforts by assessing the capabilities of existing business systems and operational processes, identifying where improvement is needed, and making specific, strategic recommendations to ensure digital transformations success.",
        image: devop1,

    },
]
export const servProcess:ServicesModel[] = [
    {
        image:serv7,
        title:"AI & Data Services​",
        subtitle:'We provide expert AI and data services to help businesses transform and unlock their full potential. Our team of data scientists, data engineer, Machine Learning Engineer and AI experts use cutting edge technology to analyze data and uncover valuable insights. With our innovative solutions, businesses can optimize their operations, improve decision-making, and gain a competitive edge in the marketplace.',
        tcolor:'text-[#347295]',
        link:'services/ai-data-services'
    },
    {
        image:serv9,
        title:"Dedicated Teams",
        subtitle:'Streamline your software development and machine learning worflows with Velion’s comprehensive DevOps and MLOps services. Our experts ensure continuous monitoring, scalability, performance optimization,and security, enabling business to deliver high quality products faster and more efficiently',
        tcolor:'text-[#68A703]',
        link:'services/dedicated-teams'
    },
    // {
    //     image: serv10,
    //     title:"Software Consulting​",
    //     subtitle:'Get expert software consulting services from Velion’s experienced team of professionals. We offer solutions tailored to your business needs, including strategy, architecture, design, and development. Our goal is to help you achieve your business objective with maximum efficiency and productivity.',
    //     tcolor:'text-[#DB3236]',
    //     link:'services/software-services'
    // },
    {
        image:serv11,
        title:"Software Service​",
        subtitle:'Accelerate your software development with Velion’s customized services. Our team of experts provides end-to-end development solutions, from design and architecture to implementation and deployment. We use cutting-edge technology and agile methodology to ensure quality, efficiency, and scalability for your business.',
        tcolor:'text-[#FF9900]',
        link:'services/software-services'
    },
    {
        image:serv5,
        title:"StartUp and MVP Solutions​",
        subtitle:'Get expert software consulting services from Velion’s experienced team of professionals. We offer solutions tailored to your business needs, including strategy, architecture, design, and development. Our goal is to help you achieve your business objective with maximum efficiency and productivity.',
        tcolor:'text-[#CE1E5B]',
        link:'services/startup-solutions'
    },
    {
        image:serv8,
        title:"Technical Training",
        subtitle:'Accelerate your software development with Velion’s customized services. Our team of experts provides end-to-end development solutions, from design and architecture to implementation and deployment. We use cutting-edge technology and agile methodology to ensure quality, efficiency, and scalability for your business.',
        tcolor:'text-[#FF9900]',
        link:'services/technical-trainings'
    }
]

// startup

export const startProcess:HstModel[] = [
    {
        image: startup1,
        title:"Product Idea Validation​",
        subtitle:'Validate your startup idea and determine it’s market viability with our MVP services. Our team will help you conduct market research, analyze competition, and identify your target audience to minimize the risk of launching an unsuccessful product.',
        tcolor:'text-[#16AB5E]'
    },
    {
        image: startup2,
        title:"Prototype Design",
        subtitle:'Bring your product ideas to life with our prototype design services. Our team of designers and developers will work with you to create a visually appealing, user friendly prototype that reflects your startup vision and resonates with your target audience.',
        tcolor:'text-[#FF5F08]'
    },
    {
        image: startup3,
        title:"MVP Development​",
        subtitle:'Get your minimum viable product (MVP) developed by experts at Velion. Our end to end solution will help you develop an MVP that showcases your ideas core features and functionality while minimizing development costs and time-to-market',
        tcolor:'text-[#FFB900]'
    },
    {
        image: startup4,
        title:"Launch Strategy​",
        subtitle:'Maximize your product’s success with our launching strategy services. Our team will help you create a comprehensive launch plan that includes marketing, PR, and outreach strategies, along with post-launch support to ensure a smooth rollout and sustained success.',
        tcolor:'text-[#FA0F00]'
    }
]

export const consProcess:HstModel[] = [
    {
        image:sstra,
        title:"Strategy Consulting",
        subtitle:'Our experts devise tailored strategies that align technology with your goals, ensuring optimized processes and enhanced productivity.',
    },
    {
        image:sarchi,
        title:"Architecture Consulting",
        subtitle:'We designs scalable and efficient architectures to support your business need and drive innovation.',
    },
    {
        image:scons,
        title:"Development Consulting",
        subtitle:'We provide expert guidance to maximize efficiency, quality, and timeline of software development.',
    },
    {
        image:sdcons,
        title:"Design Consulting​",
        subtitle:'Our team of designer create intuitive and visually appealing interfaces that elevate user experience and strengthen your brand identity',
    },
    {
        image:stest,
        title:"Testing and Quality Assurance",
        subtitle:'Ensure flawless software performance. Our meticulous approach uncovers defects, ensures compliance, and enhance overall software reliability.',
    },
    {
        image:stech,
        title:"Technology Adoption and Transformation​",
        subtitle:'Stay ahead of the curve with our technology adoption and transformation consulting. We help you embrace new technologies and navigate digital transformations for sustained growth and innovation.',
    }
]

export const consApp:HstModel[] = [
    {
        image:cons1,
        title:"Discovery and Assessment",
        subtitle:'We gain deep understanding of your business goals and challenges.',
    },
    {
        image:cons2,
        title:"Strategy Development",
        subtitle:'Our team study your goals and craft a tailored roadmap to achieve your business objective.',
    },
    {
        image:cons3,
        title:"Architecture and Design",
        subtitle:'We then create a robust customized and scalable software solution.',
    },
    {
        image:cons4,
        title:"Development and Integration​",
        subtitle:'Our team is recognized for building and seamlessly integrating robust software solutions adhering best practices.',
    },
    {
        image:cons5,
        title:"Testing and Quality Assurance",
        subtitle:'We perform rigorous testing and validation on our client software solution to ensure optimal performance and reliability.',
    },
    {
        image:cons6,
        title:"Implementation and Support​",
        subtitle:'Our team smoothly deploy and provide ongoing support and maintenance services to keep your software running perfectly.',
    }
]

export const techProcess:HstModel[] = [
    {
        image: aidataML,
        title:"Data Literacy",
        subtitle:"Equip yourself with skills and tools for analyzing complex data sets, enabling informed decision-making. Essential guidance for Data & Analytics Leaders highlighting the significance of data literacy in today's data-driven landscape."
    },
    {
        image: startup2,
        title:"Geographic Information System​",
        subtitle:'Elevate your team with our technical bootcamps, onsite workshops, and online sessions. Scale up your GIS expertise with tailored training and dedicated technical supports.',
    },
    {
        image: train1,
        title:"Software Development Training",
        subtitle:'Masters the skills required to build modern, responsive web and mobile applications using the latest technologies and frameworks.',
    },
    {
        image: train2,
        title:"Cloud Computing Training",
        subtitle:'Learn how to leverage cloud platforms such as AWS, Azure, or Google Cloud to design and deploy scalable and resilient applications',
    }
]

// data ai
export const ai : ServicesModel[] = [
    {
        title:'AI Consulting',
        subtitle:`
        Get expert guidance on AI strategy, technology selection, and implementation planning
        `,
        image:aichip
    },
    {
        title:'Business Intelligence',
        subtitle:`
            Turn raw data into actionable insights that help drive growth and improve decision making
        `,
        image:aibiz
    },
    {
        title:'Location Intelligence',
        subtitle:`
            Optimize business decisions and understand customer behaviour with location based insight
        `,
        image:  ailoc 
    },
    {
        title:'Computer Vision',
        subtitle:`
            Use deep learning algorithms to analyze images and video, enabling tasks such as object recognition and image classification
        `,
        image:aiviz
    },
    {
        title:'Natural Language Processing',
        subtitle:`
            Leverage NLP to analyze and understand human language, enabling tasks such as sentiment analysis and language translation
        `,
        image:ailang
    },
    {
        title:'Data Modeling and Engineering',
        subtitle:`
            Unleash the true power of your data with our data modelling, design and engineering solutions.
        `,
        image:aimodel
    }
]

// AI and data data 

export const aiData: AidataModel[] = [
    {
        id: 1,
        title: "Generative AI",
        description: "With generative AI, you can explore new possibilities for improving customer, user and employee satisfaction. Learn how we can assist you in realising the potential of generative AI . ",
        image:  aidataLast 
    },

    {
        id: 2,
        title: "Enterprise Data + Enterprise AI",
        description: " Our expert team crafts innovative data products to drive your organization's goals. From advising to designing and running cloud-scale services, we transform data consumption for teams and customers.",
        image: aidataBig 
    },
    {
        id: 3,
        title: "Analytics and Intelligence",
        description: "Elevate your organization with our Analytics and Intelligence expertise. Transform data for strategic advantage. Streamline collection, sharing, integration for efficiency, innovation. Thrive with us.",
        image: aidataML
    },
    {
        id: 4,
        title: "Data Platforms and Management",
        description: "Empower your organization with our Data Platform and Management expertise. We're experts in data-driven organizations, offering top-tier engineers skilled in diverse management principles and databases. Trust us for unmatched excellence",
        image: aidatapimg
    },
    

]

export const aiData2: AidataModel[] = [
    {
        id: 5,
        title: "Location Intelligence",
        description: "Experience Location Intelligence redefined. We unlock global insights with GIS solutions, going beyond maps to tackle business and societal challenges. Harness the power with us.",
        image: locat
        // aidataArtificial 
    },
    {
        id: 6,
        title: "Graph Analytics & Solutions",
        description: "Unlock the potential of interconnected data with our Graph Analytics and Solutions. We specialize in leveraging graph analytics to uncover valuable connections within your data.",
        image: graph
    },
    {
        id: 7,
        title: "IoT",
        description: "When it comes to Smart cities, hospitals and factories Kainos are the experts. We build real world solutions to drive efficiency, engagement and sustainability.",
        image: aidataIOT 
    },

];

// export const dataApp : 

export const dataApp : ServicesModel[] = [
    {
        title:'Business Understanding',
        subtitle:`
        In this stage, the problem that needs to be solved is identified and business objective are defined.
        `,
        image:ai1
    },
    {
        title:'Data Understanding',
        subtitle:`
            This stage y data collection, exploration, and preparation to better understand the data and its quality
        `,
        image:ai2
    },
    {
        title:'Data Preparation',
        subtitle:`
        At this stage, the data is cleaned, transformed, and formatted to prepare it for modelin
        `,
        image:ai3
    },
    {
        title:'Modeling',
        subtitle:`
            We apply various modeling techniques to the prepared data, and the best model is selected based on its performance
        `,
        image:ai4
    },
    {
        title:'Evaluation',
        subtitle:`
        To ensure our model is the best in the market, we evaluate the performance of our selected model and evaluated based on predefined criteria.
        `,
        image:ai5
    },
    {
        title:'Deployment',
        subtitle:`
            In this final stage, the model is deployed in a production environment, and the necessary documentation and maintenance are provided to ensure it’s continued effectiveness
        `,
        image:ai6
    }
] 