export const __renderTopColor = (value:string,name?:string)=> {
   
    if (value){
        return `bg-[${value}]`
    }
}

export const __renderTextColor = (value:string,name?:string)=> {
    
    if (value){
        return `text-[${value}]`
    }
}

export const __renderBgColor = (value:number)=> {
    switch (value){
        case 0:
            return `bg-[#ECEFF6]`
        case 1:
            return `bg-lightdark`
        case 2:
            return `bg-lightsky`
        case 3:
            return `bg-lightgreen`
        case 4:
            return `bg-lightorange`
        default:
            return ''
    }
}
export const __renderTitleColor = (value:number)=> {
    switch (value){
        case 0:
            return `text-velion`
        case 1:
            return `text-darkbrown`
        case 2:
            return `text-skyblue`
        case 3:
            return `text-green`
        case 4:
            return `text-orange`
        default:
            return ''
    }
} 

export const handleContact = (value:string, name:string)=> {
    switch(name){
        case "email":
            return window.location.href = `mailto:${value}`;
        case 'call':
            return  window.open(`https://api.whatsapp.com/send/?phone=%2B${value}&text=Hello%2C+I+would+love+to+have+a+chat&type=phone_number&app_absent=0`)
        default:
            window.open(value)
    }

}