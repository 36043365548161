import React from "react";
import { Weblayout } from "../components/common/weblayout";
// import { Input } from "../components/common/inputs";
// import { Button } from "../components/common/button";
import {CTA} from "../components/Blogcomponents/CTA";
import BlogCard from "../components/Blogcomponents/card";
import BlogDataContent from "../data/BlogData";
// import BlogPost from "./blog/[id]";

export const Comingsoon = ()=> {

    return (
        <Weblayout>
            <section className="xl:pt-[100px] flex items-center justify-center bg-[url('/src/assets/svgs/coming.svg')] bg-no-repeat bg-[100%] md:bg-[150%]">
               <div className="flex px-3 flex-col items-center justify-center container">
    <CTA />

    <BlogCard 
    blogCardData={BlogDataContent}
     />

                </div>
                
            </section>
        </Weblayout>
    )
}