import { desFlow } from "../../assets/assets"
import { Weblayout } from "../../components/common/weblayout"
import { Reachout } from "../../containers/contact/sendmessage"
import { HowWeWork } from "../../containers/homepage/howwework"
import { ServicesApp, Servicesben, Serviceshero } from "../../containers/services/servicescont"
import { softdevs, softdevsApp } from "../../data/data"
export const Softdevhome = ()=> {

    return (
        <Weblayout>
            <Serviceshero 
                bg={`!bg-[#FFF5E5]`}
                img={`bg-[url('/src/assets/images/softdev.png')]`}
                title="Software Development"
                subtitle=" Our software development services combine expertise in design, architecture, implementation, and deployment, utilizing cutting-edge technology and agile methodology."
            />
            <Servicesben 
                color={`!text-[#FF9900]`} bg={`!bg-[#FFF5E5]`}
                title="Our Software Development Services" data={softdevs}
                subtitle="Trust us to deliver customized solutions that enhance the quality, efficiency, and scalability of your software development projects"
            />
            <div className="w-full pt-[50px]">
            <div className="container mx-auto flex flex-col gap-[50px] items-center">
                <span className="mx-auto max-w-[100%] flex flex-col items-center text-center text-neutral-900">
                    <h4 className="text-[2.5em]  lg:text-[3.75em] font-[700]">Our Software Development Process</h4>
                    <p className="max-w-[80%] text-[1.2em] font-[400] leading-[1.4em]">Our team utilize best practices to ensure systematic and efficient approach to building robust and high quality software solutions</p>
                </span>
                <img className="hidden lg:flex" src={desFlow}  alt="flow" /> 
            </div>
            </div>
            <ServicesApp 
                data={softdevsApp}
                gridAdd={`md:first:col-span-2`}
            />
            <HowWeWork/>
            <Reachout/>
        </Weblayout>
    )
}