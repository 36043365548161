"use client"

import React from 'react'
// import {motion } from 'framer-motion';
import { motion } from "framer-motion";

import { TbCloudComputing } from "react-icons/tb";
import { MdOutlineHealthAndSafety } from "react-icons/md";
import { TbPigMoney } from "react-icons/tb";
import { RiCustomerServiceLine } from "react-icons/ri";
import { MdAssuredWorkload } from "react-icons/md";


const industryData = [
  {
    id: 1,
    title: 'Health Care',
    icon: <MdOutlineHealthAndSafety className='w-16 h-16 text-velion-700' />,
    color: 'border-black',
    bcolor:'bg-[#347295]',
    tcolor: 'text-[#347295]',


  },
  {
id: 2,
    title: 'Financial Services',
    icon:  <TbPigMoney className='w-16 h-16 text-velion-700' />,
    color: 'border-black',
    bcolor:'bg-[#FF9900]',
    tcolor: 'text-[#FF9900]',

  },
  {
    // tech 
    id: 3,
    title: 'Technology',
    icon:     <TbCloudComputing className='w-16 h-16 text-velion-700' />,
    // <GrTechnology className='w-16 h-16 !text-velion-700' /> , 
    color: 'border-black',
    bcolor:'bg-[#DB3236]',
    tcolor: 'text-[#DB3236]',
  },
  {
    id: 4,
    title: 'Retail',
    icon:  <RiCustomerServiceLine className='w-16 h-16 text-velion-700' />,
    color: 'border-black',
    bcolor:'bg-[#68A703]',
    tcolor: 'text-[#68A703]',

  },
  {
    id: 5,
    title: 'Energy',
    icon: <MdAssuredWorkload className='w-16 h-16 text-velion-700 ' />,
    color: 'border-black',
    bcolor:'bg-[#CE1E5B]',
    tcolor: 'text-[#ce1e5b]'


  }
]

export const Industries = () => {
  return (
    <section className="text-white bg-velion-700 w-full  py-20">
       
  <div className="mx-auto max-w-screen-xl px-4 pt-6 sm:px-6 lg:px-8 lg:pt-8">
    <motion.div
    initial={{ opacity: 0.0, y: 40 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{
      delay: 0.3,
      duration: 0.7,
      ease: "easeInOut",
    }} 
    viewport={{ once : true}}
      
      className="mx-auto flex flex-col justify-center items-center">

      <h2 className="text-3xl font-extrabold text-white sm:text-5xl">
        Industries we serve
</h2>

      <p className="py-5 max-w-lg text-center text-white/80">
      We help a diverse set of organizations across industries and geographies drive business value with technology.
      </p>

      
    </motion.div>

    <div className="mx-auto py-14 gap-8 grid grid-col-2 sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:grid-cols-5 lg:max-w-5xl">
           {
          industryData.map((item) => (
            <motion.div
            initial={{ opacity: 0.0, y: 40 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{
        delay: 0.3,
        duration: 0.9,
        ease: "easeInOut",
      }} 
      viewport={{ once : true}}
            
            key={item.id} className="flex flex-col items-center space-y-3">
              <div className={`flex items-center justify-center w-24 h-24 rounded-xl bg-blue-100/90  `}>
               {item.icon}
               
               {/* <Image src={item.icon} alt={item.title} /> */}
              </div>
              <h3 className={`text-lg font-semibold `}>{item.title}</h3>
            </motion.div>
          ))
           }

        </div>

    
  </div>
</section>
  )
}
