export const Singleservice = ({data, bgColor}:any)=> {

    return (
        <div className={` ${data.id %2 === 0 ? bgColor:'bg-transparent' } py-[100px] `}>
            <div className={`container flex ${data.id % 2 === 0 && 'flex-row-reverse'} mx-auto items-center justify-between`}>
                <img src={data.image} className="xl:w-[500px]"  alt={data.title} />
                <div className={`flex flex-col gap-5 xl:max-w-[550px] text-left ${bgColor === 'bg-deepbrown' && 'text-white'} `}>
                    <span className="flex flex-col gap-0 w-full">
                        <p className="text-velion font-[300] text-[4.4em]">{data.id}.</p>
                        <p className={`font-[700] text-[3em] leading-[1.2em] mt-[-10px] w-full
                            ${data.id % 2 === 0 && bgColor === 'bg-deepbrown' ? 'text-white' : 'text-deepbrown'}
                        `}>{data.title}</p>
                    </span>
                    <p className={`font-[300] leading-[1.4em] ${data.id % 2 === 0 && bgColor === 'bg-deepbrown' ? 'text-white' : 'text-deepbrown'}`}>
                        {data.subtitle}
                    </p>
                </div>

                
            </div>
        </div>
    )
}