import React from 'react'
import { banner } from '../../assets/assets'

 export const CTA = ()  =>{
  return (
   
<div className="overflow-hidden relative lg:flex lg:items-center">
    <div className="md:w-1/2 py-14 px-4 sm:px-6 lg:py-20 lg:px-8 z-20">
        <h6 className="text-velion-700  font-semibold text-lg text-start tracking-wide">Featured</h6>
        <h2 className="text-xl font-semibold pb-3 text-start text-black sm:text-2xl">
            <span className="block font-serif">
                Generative AI use cases and applications: Exploring GenAI's impact accross industries
            </span>
        </h2>
        <p className="text-md text-start mt-3  tracking-wide leading-8 text-gray-600">
            {/* 5 lines long lorem  */}
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </p>
        <div className="lg:mt-2  flex justify-start items-start">
            <div className="mt-3 inline-flex rounded-sm shadow">
                <button type="button" className="py-2 px-4  bg-velion-700 hover:bg-velion-800 focus:ring-velion-500 focus:ring-offset-velion-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                    Read more
                </button>
            </div>
        </div>
    </div>
    <div className="flex md:w-1/2 items-center p-1 ">
        <img src={banner} className="w-full h-96 md:h-full rounded-lg" loading='eager' alt="Banner"/>
       
    </div>
</div>

  )
}

export default CTA