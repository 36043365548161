import {motion} from "framer-motion";
import robot from "../../assets/images/services/ser.webp"
import hero1 from "../../assets/images/services/serv6.webp"
import quote from "../../assets/svgs/quote.svg"
export const Abouthero = ()=> {
    return (
        <section className="w-full h-full pt-60 mx-auto">

            {/* our vision  */}
            <motion.div
                initial={{y:100, opacity:0}}
                animate={{y:0, opacity:1}}
                transition={{duration:1}}
             className="container mx-auto 2xl:mx-auto w-full ml-5  pb-10 flex flex-col md:flex-row  items-start justify-between">
                <div className="w-full px-2 md:w-1/2 flex flex-col items-start justify-center">
         <img
                    src={quote}
                    alt="robot"
                    className=" pt-6 w-20 lg:w-40 object-cover"
                />
                <h1 className="text-6xl 2xl:text-8xl xl:text-7xl font-bold text-velion-700 pt-4 pb-4 xl:tracking-normal tracking-wider">Our Vision</h1>
                <p className="text-left  text-grey-700 max-w-xs xl:max-w-lg xl:text-xl 2xl:text-2xl 2xl:max-w-2xl leading-8 tracking-wider font-medium text-lg mt-4 ">
                    We aim to be the best in the industry, providing quality services to our clients and helping them achieve their goals.
                    </p>
                    </div>

                    <div className="w-full md:w-1/2 flex justify-end items-end">
                    <img
                        src={hero1}
                        alt="robot"
                        className="w-52 lg:w-96 object-cover"
                    />
                    </div>                    

            </motion.div>

            {/* our mission flex image  on the right  */}

      {/* velion-700 background  */}
      <div className="w-full bg-velion-700 h-fit">

      <div className="container mx-auto 2xl:mx-auto py-10 ml-5 flex flex-col items-center justify-center lg:flex-row lg:justify-around lg:items-center">

<motion.div
initial={{y:100, opacity:0}}
animate={{y:0, opacity:1}}
transition={{duration:2}}

className="w-full lg:w-1/2 flex flex-col items-start justify-center">
    <h1 className="text-6xl xl:text-7xl font-bold text-white pt-4 pb-4 tracking-wider">Our Mission</h1>
    <p className="text-left text-white pt-3 max-w-xs xl:max-w-lg xl:text-xl 2xl:text-2xl 2xl:max-w-2xl leading-8 tracking-wider font-medium text-lg mt-4 ">
        We aim to be the best in the industry, providing quality services to our clients and helping them achieve their goals.</p>
</motion.div>

{/* fade from the left once  */}
<motion.div
initial={{x:-100, opacity:0}}
animate={{x:0, opacity:1}}
transition={{duration:2.5}}

className="w-full flex justify-end items-end lg:w-1/2">
    <img
        src={robot}
        alt="robot"
        className="w-52 lg:w-96 object-cover"
    />
</motion.div>

</div>
      </div>
            </section>
    )
}