import React from 'react'


// import { b1, b2, b3 } from '../../assets/assets';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
// import BlogDataContent from '../../data/BlogData';




// pass in BlogDataContent as a prop to the BlogCard component and map through the data to display the blog cards.

type BlogCardProps = {
  blogCardData: {
    id: string;
    title: string;
    content: string;
    content2: string;
    image: any;
    img2: any;
    date: string;
    description: string;


  }[]
}


const BlogCard = (
  { blogCardData }: BlogCardProps
) => {


  interface Params {
    id: string;
  }

  const { id } = useParams<Params>();

  const findRoute = blogCardData.find((data) => data.id === id);
  console.log(findRoute);


  return (
   <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
    {
      blogCardData.map((data, index) => {
        return (
          <div 
          key={index}
          className='w-72 md:w-80 h-fit '>
      <article className="overflow-hidden rounded-lg py-10  bg-white">
  <img
    alt=""
    src={data.image}
    className="h-56 w-full object-cover"
  />

  <div className="py-4 sm:py-6">
    <a href="/">
      <h3 className="text-lg text-start font-semibold  mb-2 text-gray-900">
        {data.title}
      </h3>
    </a>

    <p className="mt-2 text-start line-clamp-3 text-sm/relaxed text-gray-500">
      {data.description}
    </p>

    {/* read more button with white background blue text and a blue border with hover effe   ct and no Icons  */}
    <Link
    to={`/blog/${data.title}`}
    className="mt-4 flex text-start items-start justify-start w-28 text-velion-700 hover:text-velion-800 border-2  border-velion-700 p-2">
      <span className="text-sm font-medium px-2">
        Read more
      </span>

    </Link>
  </div>
</article>

</div>
        )
      })
    }
</div>
  )
}

export default BlogCard;