import { useHistory } from "react-router";

// import {motion } from "framer-motion"
import { whatwe } from "../../data/data"
import React from "react";
export const Whatwedo = ()=> {

    return (
        <section className="bg-velion h-fit py-20">
            <div className="container text-black font-serif mx-auto flex flex-col gap-20">
                <span className="flex flex-col gap-[15px] text-secondary items-center">
                    <p className="font-[400] text-[2em] leading-[1em]">
                        What we do
                    </p>
                    {/* fade rigth in aos animation */}
                    <p 
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-delay="300"
                    data-aos-once="true"
                    data-aos-anchor-placement="center-bottom"
                    className="font-[700] text-[2em] md:text-[2.5em] lg:text-[3em] leading-[1.2em] xl:w-[930px]">
                        We help category leaders navigate complexity and uncertainty
                    </p>
                    <p 
                     data-aos="fade-right"
                     data-aos-duration="1000"
                     data-aos-delay="300"
                     data-aos-once="true"
                     data-aos-anchor-placement="center-bottom"
                    className="font-[400] xl:text-[1.25em] text-grey-700 leading-[1.5em] xl:w-[1114px]">
                    We partner with industry leaders to thrive in the fast paced and changing business landscape. With over a decade of experience, we provide customized solutions to tackle complex challenges offering unparalleled value and a competitive edge.
                    </p>
                </span>

                <div className="w-full px-[0px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[20px] ">
                   
                    {
                        whatwe.map((item,index)=> (
                            <Wedo
                                key={index}
                                data={item}
                                id={index}
                            />
                        ))
                    }
                </div>
            </div>

        </section>
    )
}


const Wedo = (props:{data:any; id:number; key:number;})=> {
    const nav = useHistory()
    const [view, setView] = React.useState(false)
    return (
        // fade up in aos animation
        <div
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-delay="300"
        data-aos-once="true"
         className={"relative cols-span-1 pl-[30px] py-[40px]  lg:pl-[40px] lg:py-[45px] bg-grey-100 flex flex-col items-start gap-[15px]"}>
            <span className={`absolute left-0 right-0 mx-auto top-0 w-full h-[10px] ${props.data.bcolor} `}/>
            <span className="flex flex-col gap-[10px] text-left">
                {/* {id} */}
                {/* {props.data.color} */}
                <img src={props.data.image} alt="item" className="w-[34px]" />
                <p className={`${props.data.tcolor} font-[700] leading-7 xl:text-[1.5em]`}>{props.data.title}</p>
                <p className="font-[400] xl:text-[1em] max-w-[100%] h-[90px]">{props.data.subtitle}</p>
            </span>
            <span className="flex justify-start items-start w-full h-fit duration-500" onMouseEnter={()=> setView(true)} onMouseLeave={()=> setView(false)}>
                
                {/* wallet  */}
                {/* microscope  */}
                {/* cart */}
                {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 54 54"><path fill="none" d="M24.75 20.25h4.5V13.5H36V9h-6.75V2.25h-4.5V9H18v4.5h6.75v6.75zm-9 20.25c-2.475 0-4.477 2.025-4.477 4.5s2.002 4.5 4.477 4.5c2.475 0 4.5-2.025 4.5-4.5s-2.025-4.5-4.5-4.5zm22.5 0c-2.475 0-4.477 2.025-4.477 4.5s2.002 4.5 4.477 4.5c2.475 0 4.5-2.025 4.5-4.5s-2.025-4.5-4.5-4.5zm-22.117-7.313l.067-.27 2.025-3.667h16.762a4.479 4.479 0 003.938-2.317L47.61 11.16 43.695 9h-.023l-2.474 4.5-6.21 11.25H19.191l-.292-.608L13.86 13.5 11.723 9 9.607 4.5H2.25V9h4.5l8.1 17.078-3.037 5.512a4.35 4.35 0 00-.563 2.16c0 2.475 2.025 4.5 4.5 4.5h27v-4.5H16.695a.572.572 0 01-.563-.563z"></path></svg> */}
                {/* car */}
                {/* <path fill="none" d="M30 0H9C4.02 0 0 4.02 0 9v24c0 4.98 4.02 9 9 9l-3 3v3h3l6-6.09 6 .09V27H6V5.94L33 6v6h6V9c0-4.98-4.02-9-9-9zM9 30c1.65 0 3 1.35 3 3s-1.35 3-3 3-3-1.35-3-3 1.35-3 3-3zm46.71-13.02c-.42-1.2-1.56-1.98-2.91-1.98H31.23c-1.38 0-2.49.78-2.94 1.98L24 29.31l.03 16.53c0 1.14.93 2.16 2.07 2.16h1.86C29.1 48 30 46.86 30 45.72V42h24v3.72c0 1.14.93 2.28 2.07 2.28h1.83c1.14 0 2.07-1.02 2.07-2.16l.03-4.11V29.31l-4.29-12.33zM31.23 18H52.8l3.09 9H28.14l3.09-9zM30 36c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3zm24 0c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"></path> */}
                
                {view ? <button onClick={()=> nav.push(props.data.link)}  className="w-48 duration-500 flex gap-[10px] items-center bg-velion-700 rounded-full px-[20px]  py-[8px] text-white text-[1em] capitalize">
                    LEARN MORE
                    <i className="fas fa-arrow-right" />
                </button>
                :<i onClick={()=> nav.push(props.data.link)} className="fas fa-arrow-circle-right duration-500 text-velion-700 text-[1.5em] lg:text-[2.5em]"/> }
           
            </span>
        </div>
    )
}