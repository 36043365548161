import React from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/svgs/velionwhite.svg"
import { Links, serviceLinks } from "../../data/data";
import { Button } from "./button";
import { NavLink } from "react-router-dom";
export const Navbar = ({dist, ref}:any)=> {
    const nav = useHistory()
    const [active, setActive]= React.useState(false)

    const [open, setOpen] = React.useState(false)
    

    React.useEffect(()=> {
        return () => {
            setOpen(false)
        }
    },[])

    return (
        <div className={`w-[95vw]  md:w-[90vw] mx-auto h-20  sticky left-0 right-0 top-0 z-50 ${ dist } `} ref={ref} >
            {/* container */}
            <div className="w-full px-[40px] bg-gradient-to-tr from-velion-nav from-10% via-velion-700 via-30% to-velion-900 to-90% rounded-[10px] mx-auto h-full flex justify-between items-center">
                <img src={logo} loading="lazy" alt="Velion Logo"  className="w-[100px] h-10 md:w-auto my-auto flex" onClick={()=> nav.push('/')}/>
                <span className="hidden lg:flex items-center w-fit md:gap-[10px] lg:gap-[15px] xl:gap-[50px] ">
                    {
                        Links.map((item,index)=> (
                            <a onMouseEnter={()=> {
                                item.dropdown && setActive(true)
                            }}
                            href={item.link} 
                            //  activeClassName=" h-[100px] w-fit" 
                            className=" my-0 whitespace-nowrap  text-center px-[20px] text-white/90 text-[1em] xl:text-[1.2em] font-[500] capitalize items-center relative flex" 
                            // text-xl w-fit  after:block after:content-[''] after:absolute after:h-[3px] after:bg-velion-800 after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-500 after:origin-center
              key={index}>
                                {item.name}
                                {item.dropdown && <i className={` ${open?' rotate-180 ':' rotate-0'} pl-2 fas fa-chevron-down`} />
                                }

                                {(item.dropdown && active) && 
                                <div
                                onMouseEnter={() => {setActive(true)}}

                                onMouseLeave={()=> { setActive(false) }} 
                                
                                // fade up animation

                                data-aos="fade-up"
                                data-aos-duration="600"
                                data-aos-delay="300"
                                data-aos-once="true"




                                className="absolute -ml-16 top-12 2xl:top-20 top bg-gradient-to-tr from-velion-nav from-10% via-velion-700 via-30% to-velion-900 to-90% rounded-[10px] text-white  w-[300px] text-left hidden lg:flex flex-col items-start gap-[30px] px-[30px] py-[25px]">
                                    {
                                        serviceLinks.map((item,index)=> (
                                            <NavLink     className="my-0 text-base  hover:opacity-50 text-600 font-[500]"
                                             key={index} to={item.link}>{item.name}  </NavLink>
                                        ))
                                    }
                                </div>
                            

                                
                                }
                                {


                                }



                            </a>
                        ))
                    }
                    
                </span>
                {/* blog  single link*/}

                {/* <a href="https://blog.velion.tech" target="_blank" rel="noreferrer" 
                className="hidden lg:flex w-[180px]">
                    <span className={` bg-transparent whitespace-nowrap `}>
                        back 
                        </span>
                </a> */}

                <span className="hidden lg:flex w-[180px]" >
                    <Button name="CONTACT US" addOns={` bg-transparent whitespace-nowrap `} click={()=> nav.push('/contactus')}/>
                </span>
                {
                    open && <div className="w-full  lg:hidden h-[100vh] bg-gradient-to-tr from-velion-nav from-10% via-velion-700 via-30% to-velion-900 to-90% rounded-[10px] absolute mx-auto top-[100px] left-0 right-0 z-0">
                        <span className="relative flex flex-col gap-[30px] py-[40px] px-[30px]">
                            {
                                Links.map((item,index)=> (
                                    <span className="w-full text-left border-b-[1px] border-b-main-500 py-[10px] last:border-b-0">
                                        {!item.dropdown ? <NavLink to={item?.link} 
                                            className={`text-white/90 lg:text-[1em] font-[400] relative w-full leading-[24px] flex items-center justify-between`}
                                            key={index} onClick={()=> setOpen(false)}> 
                                            {item.name} 

                                            
                                        </NavLink> :<span className={`h-fit flex flex-col items-start`}> <p onClick={()=> setActive(!active)} className={`text-white lg:text-[1em] font-[400] relative w-full leading-[24px] flex items-start justify-between`}>
                                                {item.name} {item.dropdown && <i className={ `fas fa-chevron-down ${!open? ' rotate-180 ':' rotate-90'}`}/>} </p>
                                                {(item.dropdown && active) && <div className=" text-white  w-[300px] text-left flex flex-col items-start gap-[15px] px-[30px] py-[25px]">
                                                    {
                                                        serviceLinks.map((item,index)=> (
                                                            <NavLink  onClick={()=> setOpen(false)}  
                                                            className="my-0 opacity-[.6] hover:opacity-[1] font-[500]" key={index} to={item.link}>
                                                                
                                                                {item.name}  </NavLink>
                                                        ))
                                                    }
                                                </div>}
                                                
                                                </span> }
                                    </span>
                                ))
                            }
                            <span className="w-[200px]" >
                                <Button name="CONTACT US" addOns={`bg-[#231F20] whitespace-nowrap `} click={()=> nav.push('/contactus')}/>
                            </span>
                        </span>
                    </div>
                }
                <i onClick={()=> setOpen(!open)} className={`text-white text-[2em] flex lg:hidden ${!open?'fas fa-bars ':'fas fa-times'}`} />
            </div>
           
        </div>
    )
}
export const Navbarv1 = ({dist, ref}:any)=> {

    return (
        <div className={`w-full h-[100px] sticky left-0 right-0 top-0 z-10 ${ dist } `} ref={ref} >
            <div className="container mx-auto h-full flex justify-between items-cente">
                <img src={logo} alt="Velion Logo"  className="w-auto my-auto flex"/>
                {dist}
                <span className="flex items-center justify-between w-[50%]">
                    {
                        Links.map((item,index)=> (
                            <Link to={item.link} className="text-white lg:text-[16px] font-[400]" key={index}>
                                {item.name}
                            </Link>
                        ))
                    }
                    
                </span>
            </div>
           
        </div>
    )
}