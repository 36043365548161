// import { Datashero } from "../../containers/datas/datashero"
// import { Dataservices } from "../../containers/datas/dataservices"
import { aiFlow, dataai, img2 } from "../../assets/assets"
import { Weblayout } from "../../components/common/weblayout"
import { Reachout } from "../../containers/contact/sendmessage"
import { HowWeWork } from "../../containers/homepage/howwework"
import { Serviceshero, AiData1, AiData2 } from "../../containers/services/servicescont"
export const Dataserviceshome = ()=> {

    return (
        // <Weblayout>
        //     <Datashero/>
        //     <Dataservices/>
        // </Weblayout>
        <Weblayout>
            <Serviceshero
                image={dataai} 
                img={img2}
                bg={`!bg-[#D4DADE]`}
                title="AI & Data Service"
                subtitle="Our AI and data services empower businesses to make data-drive decisions, optimize processes, and achieve growth. Partner with us to unlock the full potential of your data and drive success."
            />
           


            <AiData1 />
            <div className="w-full h-fit flex justify-center items-center">

           <img 
           src={aiFlow}
           alt="config"

           className="w-full md:h-full h-80"
           />
           </div>
           <AiData2 />
            <HowWeWork/>
            <Reachout/>
        </Weblayout>
    )
}