import { sconsult, vfull, vhalf, img3, servicesection } from "../../assets/assets"
import { Weblayout } from "../../components/common/weblayout"
import { Reachout } from "../../containers/contact/sendmessage"
import { HowWeWork } from "../../containers/homepage/howwework"
import {  Serviceshero, } from "../../containers/services/servicescont"
import { SoftwareServices } from "../../data/data"


import { FaCheck } from "react-icons/fa";

const features = [
  {
    name: 'Custom software development.',
    description:
      ' We offer custom software development services to help you build a software solution that aligns with your unique business needs.',
    icon: FaCheck,
  },
  {
    name: 'Software architecture design.',
    description: 'We provide software architecture design services to help you create a scalable and flexible software solution.',
    icon: FaCheck,
  },
  {
    name: 'Expert Teams',
    description: 'We designs scalable and efficient architectures to support your business need and drive innovation.',
    icon: FaCheck,
  },
]

export const Softconsulthome = ()=> {

    return (
        <Weblayout>
            <Serviceshero 
                image={sconsult}
                img={img3}
                bg={`!bg-red-100`}
                title="Software Services"
                subtitle="Tap into the expertise of Velion’s experienced software consulting team. Our tailored solutions in strategy, architecture, design, and development are designed to align with your unique business needs, driving maximum efficiency and productivity to help you achieve your objective."
            />
        

    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                Why choose us
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">A better workflow</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
               we offer a wide range of software consulting services to help you maximize your business potential. Our services include strategy, architecture, design, and development.

              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-left text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-left text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline ">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src={servicesection}
            alt="Product screenshot"
            className="w-[38rem] max-w-none rounded-xl sm:w-[50rem] lg:w-full lg:h-full lg:max-w-none lg:rounded-none lg:shadow-none lg:ring-0"
            width={1432}
            height={1442}
            loading="eager"
          />
        </div>
      </div>
    </div>
  

    <section className='mb-16 relative h-[450px]'>

          
<div className='w-full container py-20 h-full'>
<div className='absolute  top-0 right-0'>
<img src={vfull}  alt="svg logo" 
data-aos="flip-right"
data-aos-duration="2000"

className='w-48 h-48 md:w-full' />
</div>

<div className='absolute  bottom-0 left-0'>
<img src={vhalf}  alt="svg logo" 
data-aos="flip-right"
data-aos-duration="2000"

className='w-40 h-40 md:w-full' />
  </div>
    <div className=" flex flex-col justify-center items-start p-3">

        <h5 className="text-3xl lg:text-4xl pb-3 font-bold text-[#DB3236]">
            Software-driven Innovation Within your Reach.
        </h5>

        <p className="text-base text-start 2xl:text-lg text-gray-500 leading-snug tracking-wide mt-5">

    We help businesses leverage our end-to-end software application development, maintenance, and management services as well as innovative solutions that deliver measurable business value. We utilize our in-depth technical skills and strong domain expertise to develop future-proof solutions to assist our customers in accomplishing their strategic goals.

            </p>

        

        </div>

    </div>
    </section>

                <section className='bg-[#F8F8F8] py-10'>

                    <div className='container mx-auto flex flex-col justify-center items-center'>

                        {/* Grid here */}
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>

                    {/* map here  */} {

                        SoftwareServices.map((item,index)=> (

                            <div 
                            key={index}
                            className={ ` text-left  flex flex-col font-serif flex-wrap shadow-lg rounded-md pt-7 px-7 2xl:pt-8 2xl:px-8 ${item.bgcolor}` }>
                            <h5 className='text-2xl md:text-3xl font-medium p-0 mb-4  text-[#393A4A]'>
                                {item.title}
                            </h5>
                            <p className='text-black/70 text-sm 2xl:text-base mt-2'>
                                {item.subtitle}
                            </p>
                <div className="w-fit mx-auto h-fit pt-5">
                        
                                <img src={item.image}
                                 alt={item.title}
                                 loading="lazy"
                                  className='w-full h-40 ' />
                                </div>
                                </div>
    
                            ))

                    }

                        {/* <div className='bg-[#F0F5FF] text-left  flex flex-col font-serif flex-wrap shadow-lg rounded-lg pt-7 px-7 2xl:pt-8 2xl:px-8'>
                            <h5 className='text-2xl md:text-3xl font-medium p-0 mb-4  text-[#393A4A]'>
                                Software Consulting Services
                            </h5>
                            <p className='text-black/70 text-sm 2xl:text-base mt-2'>
                                We offer a wide range of software consulting services to help you maximize your business potential. Our services include strategy, architecture, design, and development.
                            </p>
                <div className="w-fit mx-auto h-fit pt-5">

                            <img src={group1} alt="img" className='w-full h-40 ' />
                            </div>
                            </div> */}
                            

                                        </div>
                        </div>



                    </section>
            {/* <Servicespro data={consProcess} 
                title="Our Software Consulting Services"
                subtitle="Maximize your business potential with our comprehensive software consulting services."
                tcolor={`text-[#DB3236]`}
            /> */}
             {/* <ServicesApp 
                title="Our 6-steps Software Consulting Process"
                data={consApp}
            /> */}
            <HowWeWork/>
            <Reachout/>
        </Weblayout>
    )
}