import { ourValues } from "../../data/data"
import { __renderBgColor, __renderTitleColor } from "../../data/utils"

export const Values = ()=> {

    return (
        <section className="w-full h-fit py-36">
            <div className="container mx-auto w-full">
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-5  gap-y-9 w-full">

                    <div className={`col-span-1 h-[330px] py-5 px-6 flex flex-col gap-5 text-left justify-center `}>
                        <p className="text-velion-700 font-[700] text-[3em] md:text-[4.6em] xl:text-[5.2rem] leading-[1]">

                            Our Values
                        </p>
                        <p className="text-grey-700 font-[400] text-[1.25em]">
                            These are the principles that guide us.
                        </p>
                    </div>
                    
                    {
                        ourValues.map((item,index)=> (
                            <Singlevalue
                                key={index}
                                data={item}
                                id={index}
                            />
                        ))
                    }
                </div>
            </div>
        </section>
    )
    
    
}

export const Singlevalue = ({data,id}:any)=> {

    return (
        <div className={`w-full col-span-1 h-[330px] py-5 px-6 flex flex-col gap-7 text-left ${__renderBgColor(id)}`}>
            <p className={`font-[700] text-[1.2em] uppercase ${__renderTitleColor(id)}`}>{data.title}</p>
            <p className="text-deepbrown font-[400] text-[1em]">
                {data.subtitle}
            </p>
        </div>
    )
}
