import { gStart, howWe } from "../../data/data"
export const HowWeWork = ()=> {

    return (
        <section className="w-[90vw]  lg:w-full h-fit mx-auto flex flex-col gap-[100px] pt-[50px] pb-[150px] container">
            <div className="w-full mx-auto flex flex-col gap-[50px]">
                <p className="my-0 text-[2.5em] font-[600] leading-[1.4em]">
                    How We Work
                </p>

                <div className="w-full px-[0px] grid grid-col-1 md:grid-cols-2 items-center justify-center lg:grid-cols-3 text-center gap-[30px] xl:gap-[80px] ">
                    {
                        howWe.map((item,index)=> (
                            <Singlhowwe
                                key={index}
                                data={item}
                                id={index}
                            />
                        ))
                    }
                </div>
            </div>
            <div className="w-full mx-auto flex flex-col gap-[50px]">
                <p className="my-0 text-[2.5em] font-[600] text-velion-700 leading-[1em]">
                    Get Started Today
                </p>

                <div className="w-full px-[0px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-between gap-[30px] xl:gap-[50px] ">
                    {
                        gStart.map((item,index)=> (
                            <Singlhowwe
                                key={index}
                                data={item}
                                id={index}
                                type="gstart"
                            />
                        ))
                    }
                </div>
            </div>

        </section>
    )
}


const Singlhowwe = (props:{data:any; id:number; key:number; type?:string;})=> {

    return (
        <div 
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-delay="300"
        data-aos-once="true"
        className={`relative w-full h-fit cols-span-1 flex flex-col items-center lg:items-start gap-[30px]`}>
             <img src={props.data.image} alt="item" className="w-[80px]" />
            <span className="flex flex-col gap-[10px] md:w--[300px] lg:text-left">
               
                <p className={`${props.data?.tcolor} my-0  text-secondary font-[600] leading-7 xl:text-[1.25em]`}>{props.data.title}</p>
                <p className="my-0 font-[300] xl:text-[1em] max-w-[100%] lg:max-w-[330px]">{props.data.subtitle}</p>
            </span>
        </div>
    )
}