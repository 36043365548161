import React from 'react'


import { lock, key} from '../../assets/assets';


// Blog card data
const blogCardData = [ 
  {
    id: '1',
  title: 'Solution',
  description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae dolores, possimus pariatur animi temporibus nesciunt praesentium dolore sed nulla ipsum eveniet corporis quidem, dignissimos. Molestias explicabo corporis voluptatem?',
  image: lock
},
{
  id: '2',
  title: 'Requirement',
  description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae dolores, possimus pariatur animi temporibus nesciunt praesentium dolore sed nulla ipsum eveniet corporis quidem, dignissimos. Molestias explicabo corporis voluptatem?',
  image: key
},



]


const CardwithIcon = () => {

  return (

   <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-8">
    {
      blogCardData.map((data, index) => {
        return (
          <div 
          key={index}
          className='w-80 md:w-96 h-fit '>
      <article className="overflow-hidden rounded-lg py-10 ">
  <img
    alt=""
    src={data.image}
    className="h-16 w-16 object-cover"
  />

  <div className="py-4 sm:py-6">
    <a href="/">
      <h1 className="text-xl text-start font-semibold  mb-2 text-white">
        {data.title}
      </h1>
    </a>

    <p className="mt-2 text-start line-clamp-3 text-base leading-relaxed tracking-widest text-white/90">
      {data.description}
    </p>

   
  </div>
</article>

</div>
        )
      })
    }
</div>
  )
}

export default CardwithIcon;