import React from 'react'


import { idea, location, context} from '../../assets/assets';
// Blog card data


const blogCardData = [ 
  {
    id: '1',
  title: 'Solution',
  description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae dolores, possimus pariatur animi temporibus nesciunt praesentium dolore sed nulla ipsum eveniet corporis quidem, dignissimos. Molestias explicabo corporis voluptatem?',
  image: idea,
},
{
  id: '2',
  title: 'Requirement',
  description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae dolores, possimus pariatur animi temporibus nesciunt praesentium dolore sed nulla ipsum eveniet corporis quidem, dignissimos. Molestias explicabo corporis voluptatem?',
  image: location
},
{
  id: '3',
  title: 'Other',
  description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae dolores, possimus pariatur animi temporibus nesciunt praesentium dolore sed nulla ipsum eveniet corporis quidem, dignissimos. Molestias explicabo corporis voluptatem?',
  image: context
},
{
    id: '4',
  title: 'Solution',
  description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae dolores, possimus pariatur animi temporibus nesciunt praesentium dolore sed nulla ipsum eveniet corporis quidem, dignissimos. Molestias explicabo corporis voluptatem?',
  image: idea,
},
{
  id: '5',
  title: 'Requirement',
  description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae dolores, possimus pariatur animi temporibus nesciunt praesentium dolore sed nulla ipsum eveniet corporis quidem, dignissimos. Molestias explicabo corporis voluptatem?',
  image: location
},
{
  id: '6',
  title: 'Other',
  description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae dolores, possimus pariatur animi temporibus nesciunt praesentium dolore sed nulla ipsum eveniet corporis quidem, dignissimos. Molestias explicabo corporis voluptatem?',
  image: context
},

]


const CardwithText = () => {

  return (
    <div className='flex flex-col justify-center items-start p-2'>
            section Text 
            <div className='max-w-4xl py-8 text-start'>
                <h3 className='max-w-3xl text-white text-3xl font-mono font-bold '>
                LeewayHertz’s Comprehensive Solution for the Geospatial Intelligence and Analytics Company Entailed

                </h3>
            </div>
        
         <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
    {
      blogCardData.map((data, index) => {
        return (
          <div 
          key={index}
          className='w-72  h-fit '>
      <article className="overflow-hidden rounded-lg py-10 ">
  <img
    alt=""
    src={data.image}
    className="h-20 w-20 object-cover"
  />

  <div className="py-4">
    <a href="/">
      <h3 className="text-lg text-start font-semibold  mb-2 text-white">
        {data.title}
      </h3>
    </a>

    <p className="mt-2 text-start line-clamp-3 text-sm/relaxed text-white/90">
      {data.description}
    </p>

   
  </div>
</article>

</div>
        )
      })
    }
</div>
    </div>
  
  )
}

export default CardwithText;