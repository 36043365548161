import { Singleservice } from "../../components/common/singleservice"
import { ctos } from "../../data/data"

export const Ctoservices = ()=> {

    return (
        <section className="flex flex-col gap-12">
            {
                ctos.map((item,index)=> (
                    <Singleservice
                        key={index}
                        data={item}
                        bgColor="bg-deepbrown"
                    />
                ))
            }
        </section>
    )
}
