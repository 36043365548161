import { img, startup } from "../../assets/assets"
import { Weblayout } from "../../components/common/weblayout"
import { Reachout } from "../../containers/contact/sendmessage"
import { HowWeWork } from "../../containers/homepage/howwework"
import { Serviceshero, Servicespro } from "../../containers/services/servicescont"
import { startProcess } from "../../data/data"
export const Startuphome = ()=> {

    return (
        <Weblayout>
            <Serviceshero 
                image={startup}
                img={img}
                bg={`!bg-[#E3D1D8]`}
                title="StartUps & MVP Service"
                subtitle="Turn your ideas, vision and passion into a successful business, with our end-to-end solutions for product design, development, launch and more."
            />
            <Servicespro data={startProcess} />
            <HowWeWork/>
            <Reachout/>
        </Weblayout>
    )
}