import { Link } from "react-router-dom";
import arrow from "../../assets/svgs/arrowdown.svg"
import { Button } from "../../components/common/button";
import { motion } from "framer-motion";
import Typed from "react-typed";

export const Hero = ()=> {
    return (
        <section className="w-full h-[1000px] font-mono pt-[250px]  pb-[200px] relative bg-[url('/src/assets/images/homehero.png')] bg-no-repeat bg-center bg-cover">
            {/* <img className="absolute mx-auto left-0 right-0 bottom-[-100px] z-10" src={arrow} alt="arrow down" /> */}
            <div className="container mx-auto flex justify-start items-start h-full text-left">
                <span className="lg:max-w-[50vw] flex flex-col gap-[40px]">
                    <span >
                        <p className="my-0 text-[2.5em] md:text-[3em] lg:text-[4em] text-grey-600 font-[700] leading-[1.5em] capitalize">
                            Drive <strong className="font-[700] text-velion-700">
                                <Typed
                                    strings={["Innovation", "Impact", "Strategy"]}
                                    typeSpeed={100}
                                    backSpeed={100}
                                    loop
                                />
                            </strong> with customized technology
                        </p>
                        {/* fade up animation */}
                        <motion.p
                            initial={{opacity: 0, y: 100}}
                            animate={{opacity: 1, y: 0}}
                            transition={{duration: 1.5}}

                         className="max-w-[85%] lg:max-w-[40vw] my-0 text-md md:text-xl font-[400] text-grey-700 leading-[1.4em]">
                            We build cutting-edge and innovative solutions for growth, impact, and transformation
                        </motion.p>
                    </span>

                    <motion.span
                    initial={{opacity: 0, y: 100}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 1.7}}
                     className="w-[200px] shadow-xl shadow-blue-900">

                        <Link to={'/contactus'}>

                        <Button name="Let's Discuss" addOns={` !rounded-b-[0px]`}  />
                        </Link>
                    </motion.span>
                    
                </span>
               
            </div>
        </section>
    )
} 

export const Herov1 = ()=> {
    return (
        // bg-[url('/src/assets/images/banner.png')]
        <section className="w-100  py-[300px] relative  bg-no-repeat bg-center bg-cover">
            <img className="absolute mx-auto left-0 right-0 bottom-[-100px] z-10" src={arrow} alt="arrow down" />
            <div className="container mx-auto flex justify-center items-center h-full">
                <p className=" text-[3em] text-white font-[700] leading-[1.4em]">
                        Enabling businesses to create sustainable  competitive advantage by leveraging information and technology.
                </p>
            </div>
        </section>
    )
} 