import { back } from "../../assets/assets"

export const Button = (props:any)=> {

    return (
        <button onClick ={()=>props.click()} className={` ${props.addOns}
            bg-velion-900 w-full h-[50px] text-white/90 text-[1em] font-[600] flex items-center justify-center
             rounded-[10px] px-[25px] py-[14px] capitalize 
        `}
        >
            {props.name}
        </button>
    )
}

export const Back = (props:any)=> {

    return (
        <span className=" flex items-center gap-[7px]" onClick={()=> props.click()}>
            <img src={back} className="w-10 h-10" loading="lazy" alt="Back" />
            <p className="my-0 text-[1.4em] md:text-[1.8em] text-gray-800">Back</p>
        </span>
    )
}