import { img2 } from "../../assets/assets"
import { Weblayout } from "../../components/common/weblayout"
import { Reachout } from "../../containers/contact/sendmessage"
import { HowWeWork } from "../../containers/homepage/howwework"
import { AllDevops, Serviceshero } from "../../containers/services/servicescont"
// import { devops, devopsApp } from "../../data/data"
export const Devopshome = ()=> {

    return (
        <Weblayout>
            <Serviceshero 
                bg={`!bg-[#D9DFCE]`}
                img={img2}
                title="Dedicated Teams"
                subtitle=" Our Dedicated Teams help businesses streamline software development, deployment, and AI operations, with a focus on scalability, security, and compliance."
            />
            <AllDevops />
            {/* <Servicesben 
                color={`!text-[#68A703]`} bg={`!bg-[#F0F6E6]`}
                title="Transform your Business with our Custom DevOps and MLOps Services" data={devops}
                subtitle="Streaming your company's software delivery, and optimize AI capabilities, all while maintaining top-notch security, compliance and scalability"
            /> */}
            {/* <ServicesApp 
                title="Our Approach to DevOps and MLOps Services"
                data={devopsApp}
            /> */}
            <HowWeWork/>
            <Reachout/>
        </Weblayout>
    )
}