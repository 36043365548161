import { techtrain } from "../../assets/assets"
import { Weblayout } from "../../components/common/weblayout"
import { Reachout } from "../../containers/contact/sendmessage"
import { HowWeWork } from "../../containers/homepage/howwework"
import { Serviceshero, Servicespro } from "../../containers/services/servicescont"
import { techProcess } from "../../data/data"

import { trainB } from "../../assets/assets"
export const Traininghome = ()=> {

    return (
        <Weblayout>
            <Serviceshero 
                image={techtrain}
                img={trainB}
                bg={`!bg-velion-100`}
                title="Technical
                Training"
                subtitle="Our technical training services empower individuals and teams to upskill in key technologies and platforms. Through customized programs and expert guidance, we enable you to stay ahead in your career, enhance team competencies, and drive business success in rapidly evolving tech landscape."
            />
            
            <Servicespro data={techProcess} />
            
            {/* <Servicespro data={techProcess} 
                title="Our Technical Training services"
                subtitle="Stay ahead in the dynamic tech landscape with our specialized technical training services"
                tcolor={`text-velion-700`}
            /> */}
            <HowWeWork/>
            <Reachout/>
        </Weblayout>
    )
}