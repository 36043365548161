/* eslint-disable array-callback-return */
import React from 'react';

import { Weblayout } from '../../components/common/weblayout';
import Herosection from '../../components/BlogTemlate/Herosection';
import CardwithIcon from '../../components/BlogTemlate/CardwithIcon';
import CardwithText from '../../components/BlogTemlate/CardwithText';
import BlogDataContent from '../../data/BlogData';


import { useParams } from 'react-router';
import { bannerB } from '../../assets/assets';




export const BlogTemplate = () => {


    const { id } = useParams<Params>(

    );


    interface Params {
        id: string;
    }

    return (
        <Weblayout>
            <section className="xl:pt-[100px]  bg-[#1f2020] w-full flex flex-col items-center justify-center">
            
          
           {
                BlogDataContent.map((blogData, index) =>
                 {
                     if(blogData.title === id){
                          return <Herosection key={index} content={blogData} />
                     }
                    }
                    
                    
                )
            }
                

           
          
            <div className='block w-full md:px-16'>

            <CardwithIcon />
            <CardwithText />
            </div>
            {/* full width banner image here */}

            <div className="w-full h-[550px] py-10 bg-[#1f2020] flex items-center justify-center">

                <img src={bannerB} alt="banner" className="w-full h-full object-cover" />

            </div>

            {/* Tech stack section here with title on the left  and images bottom   */}

            <div className="w-full h-full bg-[#1f2020] py-8 md:pb-16 px-8 md:px-16">
                <div className="w-full h-full block items-start justify-start">
                    <div className="pl-4 h-full flex items-start justify-start">
                        <h1 className="text-4xl font-bold font-mono text-white">Tech Stack</h1>
                    </div>

                    {/* 6 images of tech stack here using grid layout */}

                    <div className="w-full h-full grid grid-cols-1 sm:grid-cols-3  lg:grid-cols-6 gap-4 mt-10">
                        <div className="w-full h-full flex flex-col items-center justify-center">
                            <img src="https://img.icons8.com/color/48/000000/react-native.png" alt="react" />
                        
                            {/* name  */}
                            <p className="text-white text-lg font-mono">React</p>

                        </div>
                        <div className="w-full h-full flex flex-col items-center justify-center">
                            <img src="https://img.icons8.com/color/48/000000/nodejs.png" alt="node" />
                        </div>
                        <div className="w-full h-full flex flex-col items-center justify-center">
                            <img src="https://img.icons8.com/color/48/000000/mongodb.png" alt="mongo" />
                        </div>
                        <div className="w-full h-full flex flex-col items-center justify-center">
                            <img src="https://img.icons8.com/color/48/000000/graphql.png" alt="graphql" />
                        </div>
                        <div className="w-full h-full flex flex-col items-center justify-center">
                            <img src="https://img.icons8.com/color/48/000000/javascript.png" alt="javascript" />
                        </div>
                        <div className="w-full h-full flex flex-col items-center justify-center">
                            <img src="https://img.icons8.com/color/48/000000/typescript.png" alt="typescript" />
                        </div>
                        </div>


                    </div>
                </div>
               
            </section>
        </Weblayout>
    )
}
