import React from "react"
import { team } from "../../data/data"
import { TeamModel } from "../../data/model"
// import { TeamModel } from "../../data/model"

export const Teamabout  = ()=> {

    return (
        <section className="w-full relative bg-[#231F20] h-[600px] md:h-[30em] pt-[100px] mb-[40em] md:mb-[100px]  lg:mb-[300px] bg-[url('/src/assets/svgs/bgimage.svg')] bg-no-repeat bg-top bg-cover">
            <div className="container flex flex-col gap-20 mx-auto">
                <span className="w-[90%] md:w-[25em]  xl:w-[45em] flex flex-col gap-[10px] text-white text-left">
                    <h4 className="text-[2.5em] md:text[3em]  xl:text-[4.5em] font-[700] leading-[1.2em]">Meet our Leadership Team</h4>
                    <p className="text-[1.2em] font-[400] ">People build technology, and great teams build technology that really matters.</p>
                </span>
                <div
                   data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-delay="300"
                    data-aos-once="true"
                  className="absolute left-0 right-0 mx-auto bottom-[-100%] md:bottom-[-30%] lg:bottom-[-20em] grid grid-cols-1 md:grid-cols-3  lg:grid-cols-3 gap-[10px] px-[20px] md:px-[50px] lg:container lg:gap-[25px]">
                    {
                        team.map((item,index)=> (
                            <Singleteam
                                key={index}
                                data={item}
                            />
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

interface teamProps {
    data:TeamModel
}
const Singleteam :React.FC<teamProps> = ({data})=> {
    const [open,setOpen] = React.useState(false)
    return (
        <div onMouseEnter={()=> setOpen(true)} onMouseLeave={()=>setOpen(false)} className={`hover:bg-velion col-span-1 bg-grey-100 ${open === false ? 'py-[40px] lg:py-[80px] px-[50px]': 'py-[30px] pl-[20px]' }  w-full  cursor-pointer`}>
            {open === false ? <div className="flex flex-col items-center gap-[25px] mx-auto group-hover:hidden hover:transition-all transition ease-in-out delay-1500">
                <div className="w-[70px] xl:w-[102px] xl:h-[102px] rounded-[25px]">
                    <img src={data.image} alt={data.name + data.id} className="w-[100%] rounded-[25px]" />
                </div>
                <span className="w-full flex flex-col text-center gap-[8px]">
                    <p className=" text-deepbrown text-[1em] lg:text-[1.3em] font-[500]">{data.name}</p>
                    <p className=" text-velion-700 text-[.8em] lg:text-[1em]">{data.title}</p>
                </span>
                <span className="w-fit  flex gap-[30px] text-[1em] lg:text-[1.5em] text-grey-800">
                    <i className="fab fa-linkedin"  onClick={()=> window.open(data.link)}/>
                    {/* <i className="fab fa-twitter"/> */}
                    {/* <i className="fab fa-instagram"/> */}
                </span>
            </div>:

            <div className="flex flex-col z-10 aboslute top-0 h-full w-full left-0 right-0 text-left gap-[10px]">
                <div className="flex gap-5 ">
                     <img src={data.image} alt={data.name + data.id} className="rounded-[2px] w-[40px] h-[40px]" />
                     <span className="h-[80px] ">
                         <p className="text-[1.1em] text-neutral-800">{data.name}</p>
                         <p className="text-[1em] text-velion-900 w-[80%]">{data.title}</p>
                     </span>
                      
                 </div>
                 <p className="text-[1.25] h-[130px] text-velion-900 font-[400] leading-[1.5]">
                    {data.body} 
                 </p>  
                 <span className="flex flex-col items-start gap-[40px]">
                    <span className="w-fit  flex gap-[15px]">
                        {data.cert.map((item:any,index:any)=>(
                            <img key={index} className="h-[40px]" src={item} alt="certis" />
                        ))}
                    </span>
                    <span className="w-fit  flex gap-[15px] text-[1.3em] lg:text-[1.5em] text-grey-800">
                    <i className="fab fa-linkedin"  onClick={()=> window.open(data.link)}/>
                    </span>
                 </span>
                 
             </div>}
           
        </div>
    )
}
// export const Teamabout  = ()=> {

//     return (
//         <section className="w-full h-fit py-[100px]">
//             <div className="container flex flex-col gap-20 mx-auto">
//                 <Bigtitle
//                     title="Meet our Leadership Team"
//                     subtitle="People build technology, and great teams build technology that really matters."
//                     classes="text-deepbrown"
//                 />

//                 <div className="flex items-start justify-between gap-5 flex-wrap">
//                     {
//                         team.map((item,index)=> (
//                             <Singleteam
//                                 key={index}
//                                 data={item}
//                             />
//                         ))
//                     }
//                 </div>
//             </div>
//         </section>
//     )
// }


// const Singleteam = ({data}:any)=> {

//     return (
//         <div className="hover:bg-velion py-8 px-8 relative md:w-[350px] max-w-[350px] h-[300px] group transition ease-in-out delay-1500 hover:transition-all cursor-pointer">
//             <div className="flex flex-col items-center gap-1 mx-auto my-auto group-hover:hidden hover:transition-all transition ease-in-out delay-1500">
//                 <img src={data.image} alt={data.name + data.id} className="rounded-full w-[50px] mb-4" />
//                 <p className="text-center text-deepbrown text-[1.1em] font-[500]">{data.name}</p>
//                 <p className="text-center text-velion text-[1em]">{data.title}</p>
//             </div>

//             <div className="hidden transition ease-in-out delay-1500 hover:transition-all group-hover:flex flex-col z-10 aboslute top-0 h-full w-full left-0 right-0 text-left gap-5">
//                 <div className="flex gap-5 ">
//                     <img src={data.image} alt={data.name + data.id} className="rounded-full w-[35px] h-[35px]" />
//                     <span className="text-white ">
//                         <p className="text-[1.1em]">{data.name}</p>
//                         <p className="text-[1em]">{data.title}</p>
//                     </span>
                      
//                 </div>
//                 <p className="text-[1.25] text-white font-[400] leading-[1.5]">
//                     {data.body}
//                 </p>  
//             </div>
//         </div>
//     )
// }