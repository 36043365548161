import { Weblayout } from "../../components/common/weblayout"
import { Ctohero } from "../../containers/cto/ctohero"
import { Ctoservices } from "../../containers/cto/ctoservices"
export const Ctoserviceshome = ()=> {

    return (
        <Weblayout>
            <Ctohero/>
            <Ctoservices/>
        </Weblayout>
    )
}